import ProjectService from '../../services/projects'
import ItemsService from '../../services/items'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createProject ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      ProjectService.createProject(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating project'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getProjects ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getProjects(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getProject ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ProjectService.getProject(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteProject ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ProjectService.deleteProject(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateProject ({ commit }, project) {

    return new Promise((resolve, reject) => { 
      ProjectService.updateProject(project).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  getItems ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getItems(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  getItemsV2 ({ commit },params) {
    return new Promise((resolve, reject) => { 
      ProjectService.getItemsV2(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  getItemsCategories ({ commit },params) {
    
    return new Promise((resolve, reject) => { 
      ItemsService.getItemsCategories(params).then(response=> {
        let categories = [];
        if(response.data.length>0){
          response.data.forEach(cat=> {
            const aux_ids = cat.id.split('|')
            const aux_values = cat.value.split('|');
            if(aux_ids.length>1){
              aux_ids.forEach((id, index)=>{
                categories.push({id: id, value: aux_values[index]})
              })
            }
          });
          const setUnique = new Set(categories.map(obj => obj.id));
          categories = Array.from(setUnique, id => categories.find(obj => obj.id === id)).filter(c=>c.id !="").sort((a, b) => a.id.localeCompare(b.id));
        }
        
        resolve(categories)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateItem ({ commit }, item) {

    return new Promise((resolve, reject) => { 
      ProjectService.updateItem(item).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCustomers ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getCustomers(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  getInteractions ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getInteractions(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getDiscoverToken ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getDiscoverToken(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getEmbeddings ({ commit },payload) {

    return new Promise((resolve, reject) => { 
      ProjectService.getEmbeddings(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  createRedirect ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      ProjectService.createRedirect(payload).then(response => { 
        if (response.data) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating project'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getSearchRedirects ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ProjectService.getSearchRedirects(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteRedirect ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ProjectService.deleteRedirect(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateRedirect ({ commit }, project) {

    return new Promise((resolve, reject) => { 
      ProjectService.updateRedirect(project).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}