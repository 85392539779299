<script>

import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
import Trigger from "@/components/journeys/configs/trigger.vue"
import DelayTime from "@/components/journeys/configs/time-delay"
import ConditionSimple from "@/components/journeys/configs/condition-simple.vue";
import ConditionComplex from "@/components/journeys/configs/condition-complex.vue";
import ChannelVariants from "@/components/journeys/configs//channel-variants.vue"
import ABTesting from "@/components/journeys/configs/ab-testing.vue"
import DelayCondition from "@/components/journeys/configs/wait-condition.vue";
import DelayConditionInteraction from "@/components/journeys/configs/wait-condition-interaction.vue";
import ActionsApi from "@/components/journeys/configs/actions-api.vue"
import ActionsScript from "@/components/journeys/configs/actions-script.vue"
import Reachability from "@/components/journeys/configs/reachability.vue"

import { FlowyDragHandle} from "@hipsjs/flowy-vue";

import {
	journeyMethods
} from "@/state/helpers";


export default {
  data() {
		return {
      showConfigModal: false,
      showConfigNormalSizeModal: false,
      isNodeOK: false,
      text:'',
      confirmDelete : false
    }
  },
  props:{
    node: Object,
    block: Object
  },
  //props: ['remove', 'node', 'block', 'updateJourneyWorkflow'],
  components:{
    Trigger,
    DelayTime,
    ConditionSimple,
    ChannelVariants,
    FlowyDragHandle,
    ABTesting,
    DelayCondition,
    DelayConditionInteraction,
    ConditionComplex,
    ActionsApi,
    ActionsScript,
    Reachability
  },
  mounted(){
    
  },
  methods:{
    ...journeyMethods,

    async onConfirmClicked(){
      switch(this.block.type) {
        case 'trigger':
          this.block.config = this.$refs.triggerComponent.getTriggerConfig();
          this.showConfigModal = false;
          break;
        case 'delay_time':
          this.block.config = this.$refs.delayTimeComponent.getTimeDelayConfig();
          this.showConfigNormalSizeModal = false;
          break;
        case 'flow_control_condition_simple':
          this.block.config = this.$refs.condition_simpleComponent.getConditionSimpleConfig();
          this.showConfigModal = false;
          break;
        case 'notification_email':
          this.block.config = this.$refs.channelVariantsComponent.getVariantsConfig();
          this.showConfigModal = false;
          break;
        case 'notification_sms':
          this.block.config = this.$refs.smsChannelVariantsComponent.getVariantsConfig();
          this.showConfigModal = false;
          break;
        case 'notification_app_push':
          this.block.config = this.$refs.appPushChannelVariantsComponent.getVariantsConfig();
          this.showConfigModal = false;
          break;
        case 'notification_web_push':
          this.block.config = this.$refs.webPushChannelVariantsComponent.getVariantsConfig();
          this.showConfigModal = false;
          break;
        case 'flow_control_ab_testing':
          this.block.config = this.$refs.abTestingComponent.getABTestingConfig();
          this.showConfigModal = false;
          this.block.updateABTestingNodes(this.block, this.node.id);
          break;
        case 'delay_condition' : 
          this.block.config = this.$refs.delayConditionComponent.getWaitConditionConfig();
          this.showConfigModal = false;
          break;
        case 'delay_condition_interaction' :
          this.block.config = this.$refs.delayConditionInteractionComponent.getWaitConditionConfig();
          this.showConfigModal = false;
          break;
        case 'flow_control_condition_complex': 
          this.block.config = this.$refs.condition_complexComponent.getConditionComplexConfig();
          this.showConfigModal = false;
          if(this.block.updateConditionPathNodes)
            this.block.updateConditionPathNodes(this.block, this.node.id);
          break;
        case 'actions_api': 
          this.block.config = this.$refs.actionApiComponent.getActionsAPIConfig();
          this.showConfigNormalSizeModal = false;
          break;
          case 'actions_script': 
            this.block.config = this.$refs.actionScriptComponent.getScriptConfig();
            this.showConfigNormalSizeModal = false;
          break;
          case 'flow_control_reachability':
            this.block.config = this.$refs.reachabilityComponent.getReachabilityConfig();
            this.showConfigNormalSizeModal = false;
            if(this.block.updateReachabilityNodes)
              this.block.updateReachabilityNodes(this.block, this.node.id);
          break;
      }
      this.isNodeOK = await this.getIsBlockValid(this.block);
      this.text = await this.getBlockText(this.block);
      
      this.block.updateJourneyWorkflow();
      
    },
    onBlockClicked(){
      let loader;
      switch(this.block.type) {
        case 'trigger':
          this.showConfigModal = true;
          // eslint-disable-next-line no-console
          console.log(this.block);
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.triggerComponent.setTriggerConfig(cloneConfig || {},this.block.journeyId);
          },1000);
          
          break;
        case 'delay_time': 
          this.showConfigNormalSizeModal = true;
          
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.delayTimeComponent.setTimeDelayConfig(cloneConfig || {});
          },1000);
          break;
        case 'flow_control_condition_simple': 
          this.showConfigModal = true;
          
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.condition_simpleComponent.setConditionSimpleConfig(cloneConfig);
          },1000);
          break;
        case 'notification_email': 
          this.showConfigModal = true;
          loader = this.$loading.show();
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.channelVariantsComponent.setVariantsConfig(cloneConfig);
            loader.hide()
          },1000);
          break;
        case 'notification_sms': 
          this.showConfigModal = true;
          loader = this.$loading.show();
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.smsChannelVariantsComponent.setVariantsConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
        case 'notification_app_push': 
          this.showConfigModal = true;
          loader = this.$loading.show();
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.appPushChannelVariantsComponent.setVariantsConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
        case 'notification_web_push': 
          this.showConfigModal = true;
          loader = this.$loading.show();
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.webPushChannelVariantsComponent.setVariantsConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
        case 'flow_control_ab_testing':
          this.showConfigModal = true;
          loader = this.$loading.show();

          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.abTestingComponent.setABTestingConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
        case 'delay_condition' : 
          this.showConfigModal = true;
          // eslint-disable-next-line no-case-declarations
          loader = this.$loading.show();
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.delayConditionComponent.setWaitConditionConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
        case 'delay_condition_interaction' :
          this.showConfigModal = true;
            setTimeout(()=>{
              let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
              this.$refs.delayConditionInteractionComponent.setWaitConditionConfig(cloneConfig);
            },1000);
          break;
        case 'flow_control_condition_complex': 
          this.showConfigModal = true;
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.condition_complexComponent.setConditionComplexConfig(cloneConfig);
          },1000);
          break;
          case 'actions_api':
          loader = this.$loading.show();
          this.showConfigNormalSizeModal = true;
          setTimeout(()=>{
            let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
            this.$refs.actionApiComponent.setActionsAPIConfig(cloneConfig);
            loader.hide();
          },1000);
          break;
          case 'actions_script':
            loader = this.$loading.show();
            this.showConfigNormalSizeModal = true;
            setTimeout(()=>{
              let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
              this.$refs.actionScriptComponent.setScriptConfig(cloneConfig);
              loader.hide();
            },1000);
            break;
            case 'flow_control_reachability':
              loader = this.$loading.show();
              this.showConfigNormalSizeModal = true;
              setTimeout(()=>{
                let cloneConfig = this.block.config ? JSON.parse(JSON.stringify(this.block.config)) : null;
                this.$refs.reachabilityComponent.setReachabilityConfig(cloneConfig);
                loader.hide();
              },1000);
            break;
      }
    },
    onRemoveNodeClicked(){
      this.confirmDelete = true;
    },
    onConfirmRemoveClicked(){
      if(this.block.removeNode){
        this.block.removeNode(this.node);
        this.confirmDelete = false;
        this.showConfigModal = false;
      }
        
    }
    
  },
  watch:{
    block: {
      immediate:true,
      deep: true,
      async handler(newVal){
        this.isNodeOK = await this.getIsBlockValid(newVal);
        this.text = await this.getBlockText(newVal);        
      },
    },
  }
}
</script>

<template>
  <div>
    <div class="card h-100 block border-right interact" :style="{ 'border-right-color': block.color}" v-if="block.type=='trigger' || block.type=='delay_time' || block.type=='flow_control_condition_simple' || block.type=='notification_email' || block.type=='notification_sms' ||  block.type=='notification_app_push' ||  block.type=='notification_web_push' || block.type=='flow_control_ab_testing' || block.type=='delay_condition' || block.type == 'delay_condition_interaction' || block.type == 'flow_control_condition_complex'|| block.type=='actions_api' || block.type=='actions_script' || block.type=='flow_control_reachability'" v-on:click="onBlockClicked">
      
        <div class="card-body p-2">
          <div class="d-inline-flex text-center">
            <i class="mdi me-1" :class="block.icon"></i><div class="h6 mb-0">{{ block.title }}</div>
          </div>
          <br>
          <span class="badge" v-if="isNodeOK" :style="{'background-color' : block.color}">{{text}}</span>
          <button class="btn btn-link btn-sm pb-0 pt-0 ps-0" @click="onBlockClicked" v-if="!isNodeOK"><i class="bx bx-wrench"></i> {{$t('journeys.journey_workflow_setup')}}</button>
          <i class="mdi mdi-alert warning-icon" v-if="!isNodeOK"></i>
          <FlowyDragHandle><i class="mdi mdi-drag-vertical drag-icon"></i></FlowyDragHandle>
      </div>
    </div>

    <div class="card h-100 block ab-testing-child" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_variant_child'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center me-2">
          <div class="h6 mb-0">{{ block.title  }}</div>
        </div>
        <span class="badge" :style="{'background-color' : '#f1b44c'}">{{`${block.ratio}%`}}</span>
      </div>
    </div>

    <div class="card h-100 block ab-testing-child" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_complex_child'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center me-2">
          <div class="h6 mb-0">{{ block.title  }}</div>
        </div>
      </div>
    </div>

    <div class="card h-100 block ab-testing-child" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_reachability_child'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center me-2">
          <div class="h6 mb-0">{{ block.title  }}</div>
        </div>
      </div>
    </div>

    <div class="card h-100 block boolean-node" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_condition_simple_child'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center">
          <div class="h6 mb-0">{{ block.title }}</div>
        </div>
      </div>
    </div>

    

    <div class="card h-100 border-right block_end" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_end'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center">
          <i class="mdi me-1" :class="block.icon"></i><div class="h6 mb-0">{{ block.title }}</div>
        </div>
        <br>
        <FlowyDragHandle><i class="mdi mdi-drag-vertical drag-icon"></i></FlowyDragHandle>
      </div>
    </div>

    <div class="card h-100 border-right block_end" :style="{ 'border-right-color': block.color}" v-if="block.type=='flow_control_cancel'">
      <div class="card-body p-2">
        <div class="d-inline-flex text-center">
          <i class="mdi me-1" :class="block.icon"></i><div class="h6 mb-0">{{ block.title }}</div>
        </div>
        <br>
        <FlowyDragHandle><i class="mdi mdi-drag-vertical drag-icon"></i></FlowyDragHandle>
      </div>
    </div>

    <b-modal
        :title="block.title"
        size="xl"
        v-model="showConfigModal"
        title-class="font-18"
        okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <Trigger ref="triggerComponent" v-if="block.type=='trigger'"></Trigger>
        <ConditionSimple ref="condition_simpleComponent" v-if="block.type=='flow_control_condition_simple'"></ConditionSimple>
        <ConditionComplex ref="condition_complexComponent" v-if="block.type=='flow_control_condition_complex'"></ConditionComplex>
        <ChannelVariants :channel="'email'" ref="channelVariantsComponent" v-if="block.type=='notification_email'"></ChannelVariants>
        <ChannelVariants :channel="'sms'" ref="smsChannelVariantsComponent" v-if="block.type=='notification_sms'"></ChannelVariants>
        <ChannelVariants :channel="'app_push'" ref="appPushChannelVariantsComponent" v-if="block.type=='notification_app_push'"></ChannelVariants>
        <ChannelVariants :channel="'web_push'" ref="webPushChannelVariantsComponent" v-if="block.type=='notification_web_push'"></ChannelVariants>
        <ABTesting ref="abTestingComponent" v-if="block.type=='flow_control_ab_testing'"></ABTesting>
        <DelayCondition ref="delayConditionComponent" v-if="block.type=='delay_condition'"></DelayCondition>
        <DelayConditionInteraction ref="delayConditionInteractionComponent" v-if="block.type=='delay_condition_interaction'"></DelayConditionInteraction>
        <template #modal-footer>
          <b-button
            variant="secondary"
            class="float-right"
            @click="showConfigModal=false">
            {{$t('common.cancel')}}
          </b-button>

          <b-button
            variant="danger"
            class="float-right"
            @click="onRemoveNodeClicked"
            v-if="block.type!='trigger'">
            {{$t('common.delete')}}
          </b-button>

          <b-button
            variant="primary"
            class="float-right"
            @click.prevent="onConfirmClicked">
            {{$t('common.confirm')}}
          </b-button>
        </template>
    </b-modal>

    <b-modal
        :title="block.title"
        v-model="showConfigNormalSizeModal"
        title-class="font-18"
        @ok.prevent="onConfirmClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <DelayTime ref="delayTimeComponent" v-if="block.type=='delay_time'"></DelayTime>
        <ActionsApi ref="actionApiComponent" v-if="block.type=='actions_api'"></ActionsApi>
        <ActionsScript ref="actionScriptComponent" v-if="block.type=='actions_script'"></ActionsScript>
        <Reachability ref="reachabilityComponent" v-if="block.type=='flow_control_reachability'"></Reachability>
        <template #modal-footer>
          <b-button
            variant="secondary"
            class="float-right"
            @click="showConfigNormalSizeModal=false">
            {{$t('common.cancel')}}
          </b-button>

          <b-button
            variant="danger"
            class="float-right"
            @click="onRemoveNodeClicked">
            {{$t('common.delete')}}
          </b-button>

          <b-button
            variant="primary"
            class="float-right"
            @click="onConfirmClicked">
            {{$t('common.confirm')}}
          </b-button>
        </template>
    </b-modal>
    <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <p>{{ $t('journeys.journey_workflow_remove_component') }}</p>
    </b-modal>
  </div>
</template>

<style scoped>
.block{
  width: 300px;
  border-width: 1px;
  border-color: #ebecf2;
  border-style: solid;
  margin-bottom: 0px;
}

.border-right{
  border-right-width: 3px;
  border-right-style: solid;
}

.block_end{
  width: 120px;
  text-align: center;
  margin-bottom: 0px;
  border-width: 1px;
  border-color: #ebecf2;
  border-style: solid;
}

.interact{
  cursor: pointer;
}

.warning-icon{
  position: absolute;
  float: right;
  top: 5px;
  right: 5px;
  font-size: 15px;
  color: #f1b44c;
}

.boolean-node{
  text-align: center;
  width: 60px;
}

.drag-icon{
  position: absolute;
  bottom: 5px;
  right: 0px;
  font-size: 20px;
}

.ab-testing-child{
  max-width: 300px;
  width: unset;
}
</style>
