import Request from '../baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createLoyaltyProgram = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/loyalty-programs`,
    payload,
    config
  )
}

const _getLoyaltyPrograms = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/loyalty-programs?${params.q}`,
    config
  )
}

const _deleteLoyaltyPrograms = loyatyProgramId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/loyalty-programs/${loyatyProgramId}`, config)
}

const _getLoyaltyProgram = loyatyProgramId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/loyalty-programs/${loyatyProgramId}`, config)
}

const _updateLoyaltyProgram = (loyaltyProgram) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/loyalty-programs/${loyaltyProgram._id}`, loyaltyProgram, config)
}

export default {
  createLoyaltyProgram: _createLoyaltyProgram,
  getLoyaltyPrograms: _getLoyaltyPrograms,
  deleteLoyaltyPrograms: _deleteLoyaltyPrograms,
  getLoyaltyProgram: _getLoyaltyProgram,
  updateLoyaltyProgram: _updateLoyaltyProgram
}