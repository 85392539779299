import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _getItems = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/items?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/items/v2`


  return Request.get(
    url,
    config
  )
}

const _getItemsCategories = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/items/categories?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/items/categories`


  return Request.get(
    url,
    config
  )
}

const _updateItem = (product) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/items/${product._id}`, product, config)
}

export default {
  getItems : _getItems,
  updateItem: _updateItem,
  getItemsCategories: _getItemsCategories
}