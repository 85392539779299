import store from '@/state/store'

export default [
  {
    path: '/metrics',
    name: 'default',
    meta: {
      authRequired: true,
      product: 'live'
    },
    component: () => import('./views/dashboards/default'),
  },
  {
    path: '/live-shopping-analyze',
    name: 'default',
    meta: {
      authRequired: true,
      product: 'live'
    },
    component: () => import('./views/dashboards/live-shopping-analyze'),
  },
  
  {
    path: '/',
    name: 'default',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/dashboards/dashboard-v2'),
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/users/users'),
  },
  {
    path: '/items',
    name: 'items',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/catalogs/items'),
  },
  {
    path: '/integrations',
    name: 'integrations',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/projects/integrations'),
  },
  {
    path: '/customers',
    name: 'customers',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/customers/customers'),
  },
  {
    path: '/hosts',
    name: 'hosts',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin']
    },
    component: () => import('./views/users/hosts'),
  },
  {
    path: '/groups',
    name: 'groups',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin']
    },
    component: () => import('./views/groups/groups'),
  },
  {
    path: '/tenants',
    name: 'tenants',
    meta: {
      authRequired: true,
      roles:['superadmin']
    },
    component: () => import('./views/users/users'),
  },
  {
    path: '/availability',
    name: 'availability',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin']
    },
    component: () => import('./views/availability/availability'),
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin','host']
    },
    component: () => import('./views/campaigns/campaigns'),
  },
  {
    path: '/events',
    name: 'events',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin','host']
    },
    component: () => import('./views/events/events'),
  },
  {
    path: '/next-events',
    name: 'next-events',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['host']
    },
    component: () => import('./views/events/next-events'),
  },
  {
    path: '/events-list',
    name: 'events-list',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin']
    },
    component: () => import('./views/events/list'),
  },
  {
    path: '/campaign',
    name: 'newCampaign',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin','host']
    },
    component: () => import('./views/campaigns/campaign'),
  },
  {
    path: '/videos',
    name: 'videos',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin']
    },
    component: () => import('./views/videos/videos'),
  },
  {
    path: '/new-video',
    name: 'newVideo',
    meta: {
      authRequired: true,
      product: 'live',
      roles:['admin','host']
    },
    component: () => import('./views/videos/new-video'),
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./layouts/vertical'),
  },
  {
    path: '/search-settings',
    name: 'search-settings',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./views/projects/search/settings'),
  },
  {
    path: '/search-analyze',
    name: 'search-analyze',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./views/projects/search/search-analyze'),
  },
  {
    path: '/broadcast-analyze',
    name: 'broadcast-analyze',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/broadcasts'),
  },
  {
    path: '/journey-analyze',
    name: 'journey-analyze',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/journeys'),
  },
  {
    path: '/search-clusters',
    name: 'search-clusters',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./views/projects/search/search-clusters'),
  },
  {
    path: '/search-list',
    name: 'search-list',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./views/projects/search/list'),
  },
  {
    path: '/search-test',
    name: 'search-test',
    meta: {
      authRequired: true,
      product: 'search',
      roles:['admin']
    },
    component: () => import('./views/projects/search/search-test'),
  },
  {
    path: '/projects',
    name: 'projects',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/projects/projects'),
  },
  {
    path: '/segmentation',
    name: 'segmentation',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/segmentation/rfm.vue'),
  },
  {
    path: '/new-project',
    name: 'new_project',
    meta: {
      authRequired: true,
      roles:['admin']
    },
    component: () => import('./views/projects/new-project'),
  },
  {
    path: '/reco-project',
    name: 'reco-project',
    meta: {
      authRequired: true,
      product: 'reco',
      roles:['admin']
    },
    component: () => import('./views/projects/reco/reco-project'),
  },
  {
    path: '/reco-analyze',
    name: 'reco-analyze',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/recommendations'),
  },
  {
    path: '/personal-analyze',
    name: 'personal-analyze',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/personalizations'),
  },
  {
    path: '/feedback-analyze',
    name: 'feedback-analyze',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/feedback'),
  },
  {
    path: '/usage',
    name: 'usage',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/dashboards/usage'),
  },
  {
    path: '/custom-strategies',
    name: 'custom-strategies',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/recommendations/custom-strategies'),
  },
  {
    path: '/personal-shoppers',
    name: 'personal-shoppers',
    meta: {
      authRequired: true,
      product: 'personal_shopper',
      roles:['admin']
    },
    component: () => import('./views/personal-shopper/personal-shoppers'),
  },
  {
    path: '/personal-shopper',
    name: 'personal-shopper',
    meta: {
      authRequired: true,
      product: 'personal_shopper',
      roles:['admin']
    },
    component: () => import('./views/personal-shopper/personal-shopper'),
  },
  {
    path: '/personal-shopper-analyze',
    name: 'personal-shopper-analyze',
    meta: {
      authRequired: true,
      product: 'personal_shopper',
      roles:['admin']
    },
    component: () => import('./views/personal-shopper/dashboard'),
  },
  
  {
    path: '/custom-strategy',
    name: 'custom-strategy',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/recommendations/custom-strategy'),
  },
  {
    path: '/audiences',
    name: 'audiences',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/audiences/audiences'),
  },
  {
    path: '/audiences-explorer',
    name: 'audiences-explorer',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/audiences/audience-explorer'),
  },
  {
    path: '/contact-lists',
    name: 'contact-lists',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/audiences/contact-lists'),
  },
  {
    path: '/audience/:id',
    name: 'edit-audience',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/audiences/audience'),
  },
  {
    path: '/audience',
    name: 'audience',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/audiences/audience'),
  },
  {
    path: '/personalizations',
    name: 'personalizations',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/personalizations/personalizations'),
  },
  {
    path: '/personalization',
    name: 'personalization',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/personalizations/personalization'),
  },
  {
    path: '/personalization-preview',
    name: 'personalization-preview',
    meta: {
      authRequired: false,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/personalizations/preview'),
  },
  {
    path: '/template',
    name: 'template',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/template'),
  },
  {
    path: '/email-components',
    name: 'email-components',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/email-components'),
  },
  {
    path: '/properties',
    name: 'properties',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/properties'),
  },
  {
    path: '/templates',
    name: 'templates',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/templates'),
  },
  {
    path: '/interactions',
    name: 'interactions',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/interactions'),
  },
  {
    path: '/goals',
    name: 'goals',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/goals'),
  },
  {
    path: '/templates-store',
    name: 'templates-store',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/templates-store'),
  },
  {
    path: '/gp-templates-store',
    name: 'gp-templates-store',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/catalogs/gp-templates-store'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('./views/account/profile'),
    meta: {
      authRequired: true,
      roles:['admin','host'],
    },
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('./views/account/company'),
    meta: {
      authRequired: true,
      roles:['admin'],
    },
  },
  {
    path: '/journey',
    name: 'journey',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/journeys/journey'),
  },
  {
    path: '/journeys',
    name: 'journeys',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/journeys/journeys'),
  },
  {
    path: '/broadcasts',
    name: 'broadcasts',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/broadcast/broadcasts'),
  },
  {
    path: '/loyalty-dashboard',
    name: 'loyalty-dashboard',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/dashboard'),
  },
  {
    path: '/loyalty-programs',
    name: 'loyalty-programs',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/programs'),
  },
  {
    path: '/loyalty-levels',
    name: 'loyalty-levels',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/levels'),
  },
  {
    path: '/loyalty-campaigns',
    name: 'loyalty-campaigns',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/campaigns'),
  },
  {
    path: '/loyalty-fulfillments',
    name: 'loyalty-fulfillments',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/fulfillments'),
  },
  {
    path: '/loyalty-rewards',
    name: 'loyalty-rewards',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/rewards'),
  },
  {
    path: '/loyalty-members',
    name: 'loyalty-members',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/members'),
  },
  {
    path: '/loyalty-reward',
    name: 'loyalty-reward',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/reward'),
  },
  {
    path: '/loyalty-campaign',
    name: 'edit-loyalty-campaign',
    meta: {
      authRequired: true,
      product: 'loyalty',
      roles:['admin']
    },
    component: () => import('./views/loyalty/campaign'),
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    meta: {
      authRequired: true,
      product: 'personal',
      roles:['admin']
    },
    component: () => import('./views/broadcast/broadcast'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        
        store.dispatch('auth/logOut').then(()=>{
          // Navigate back to previous page, or home as a fallback
          next({ path: 'login' })
        });
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  
]
