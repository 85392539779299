import Request from '../baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createLoyaltyLevel = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/loyalty-levels`,
    payload,
    config
  )
}

const _getLoyaltyLevels = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/loyalty-levels?${params.q}`,
    config
  )
}

const _deleteLoyaltyLevels = loyaltyLevelId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/loyalty-levels/${loyaltyLevelId}`, config)
}

const _getLoyaltyLevel = loyaltyLevelId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/loyalty-levels/${loyaltyLevelId}`, config)
}

const _updateLoyaltyLevel = (loyaltyLevel) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/loyalty-levels/${loyaltyLevel._id}`, loyaltyLevel, config)
}

export default {
  createLoyaltyLevel: _createLoyaltyLevel,
  getLoyaltyLevels: _getLoyaltyLevels,
  deleteLoyaltyLevels: _deleteLoyaltyLevels,
  getLoyaltyLevel: _getLoyaltyLevel,
  updateLoyaltyLevel: _updateLoyaltyLevel
}