import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword','request_reset_password','password_reset'])

export const userMethods = mapActions('users', ['getUsers', 'createUser', 'getUser', 'updateUser','deleteUser','uploadPicture','updateUserProfile','getCompany','updateCompany'])

export const campaignMethods = mapActions('campaigns', ['getCampaigns', 'createCampaign', 'getCampaign', 'updateCampaign','deleteCampaign','uploadCampaignPicture','getProducts','uploadPicture','updateProduct', 'updateProductCampaign', 'getCampaignDefaultSettings'])

export const videoMethods = mapActions('videos', ['getVideos', 'createVideo', 'getVideo', 'updateVideo','deleteVideo','uploadFile'])

export const eventMethods = mapActions('events', ['getEvents', 'createEvent', 'getEvent', 'updateEvent','deleteEvent', 'getHostsAvailable','createShowEvent'])

export const groupMethods = mapActions('groups', ['getGroups', 'createGroup', 'getGroup', 'updateGroup','deleteGroup'])

export const projectMethods = mapActions('projects', ['getProjects', 'createProject', 'getProject', 'updateProject','deleteProject','getItems', 'updateItem' ,'getCustomers','getInteractions', 'getItemsCategories', 'getDiscoverToken', 'getEmbeddings','getSearchRedirects', 'createRedirect','deleteRedirect', 'updateRedirect', 'getItemsV2'])

export const scenarioMethods = mapActions('scenarios', ['getScenarios', 'createScenario', 'getScenario', 'updateScenario','deleteScenario'])

export const availabilityMethods = mapActions('availability', ['getAvailabilities', 'createAvailability', 'getAvailability', 'updateAvailability','deleteAvailability', 'getTimeSlotsByAvailability', 'validateTimeSlotAvailability']);

export const commonMethods = mapActions('common', ['uploadMedia', 'getNews', 'getRecommendationStrategies', 'getFonts'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const searchAnalyticsMethods = mapActions('searchAnalytics', ['getLastSyncDate', 'getTotalItemsCount', 'getTotalSearchesCount', 'getTotalSearchesWithResultsCount', 'getTotalUsersCount', 'getSearchClusters', 'getSearchesByDay', 'getTotalSearchesWithClickCount', 'getTotalSearchesAddedToCartCount','getSearchesConversionsByDay', 'getSearchesList', 'getResultsBySearch', 'getSearchIndicators', 'getSearchCustomerCount', 'getSearchCustomerCTR', 'getSearchCustomerCR', 'getSearchTopProducts', 'getSearchTopQueries']);

export const analyticsMethods = mapActions('analytics', ['getLastSyncDate', 'getTotalItemsCount', 'getTotalInteractionsCount', 'getTotalInteractionsByTypeCount', 'getTotalCustomersCount', 'getTotalInteractionsByDay', 'getRFMReport', 'getRFMReportText', 'getTotalSessionsCount', 'getRevenue', 'getRevenueRate', 'getRevenueAvg', 'getRecoDashboard', 'getActiveABTests', 'getActiveExperiences', 'getExecutingJourneys', 'getPersonalizationDashboard','getSessionsTimeline', 'getImpressionsTimeline', 'getRecoPageDashboard', 'getRevenueTimeline', 'getConversionsTimeline','getAOVTimeline','getRevenueRateTimeline','getRevenueTotalTimeline', 'getTotalSessionsTimeline','getRecoImpressionsTimeline', 'getRecoCTRTimeline', 'getRecoCRTimeline', 'getRecoSessionsTimeline', 'getRecoDirectRevenueTimeline', 'getRecoAssistedRevenueTimeline', 'getDownloadRecoCRReport', 'getNPSFeedbackReport', 'getNPSCommentsFeedbackReport', 'getPersonalizationIndicators', 'getRecommendationsIndicators', 'getFeedbackTypeReport', 'getJourneysIndicators', 'getJourneysReports', 'getBroadcastsReports', 'getTotalEmails', 'getRecommendationsCustomers', 'getPersonalizationsCustomers']);

export const liveshoppingAnalyticsMethods = mapActions('liveShoppingAnalytics', ['getEventsStats','getEventsGeneralStats', 'getEventsGeneralStatsLastSyncDate','getEventsList', 'getTotalAmountSold', 'getTotalAmountSoldByDay', 'getEventsByDay', 'getAllEventsByDay']);

export const integrationMethods = mapActions('integrations', ['getIntegrations', 'createIntegration', 'updateIntegration','deleteIntegration', 'updateGoShopsIntegration', 'deleteGoShopsIntegration', 'getGoShopsIntegration', 'createGoShopsIntegration', 'ssoGoShopsIntegration'])

export const invitationsMethods = mapActions('invitations', ['getInvitations', 'createInvitation', 'getInvitation', 'updateInvitation','deleteInvitation', 'uploadInvitations',])

export const audienceMethods = mapActions('audiences', ['getAudiences', 'createAudience', 'getAudience', 'updateAudience','deleteAudience', 'createContactList', 'getContactLists', 'getContactListTemplate', 'deleteContactList', 'updateContactList', 'getContactList', 'getAudienceExploreStatus', 'getAudienceExplore'])

export const propertyMethods = mapActions('properties', ['getProperties', 'createProperty', 'updateProperty','deleteProperty'])

export const contentMethods = mapActions('contents', ['getContents', 'createContent', 'getContent', 'updateContent','deleteContent', 'updateExperience', 'updateVariant', 'createExperience', 'createVariant', 'getVariantHistory', 'generateContentFromImage'])

export const customerMethods = mapActions('customers', ['getCustomers', 'getCustomersCountries', 'getRFMSegments','getCustomerFixedProperties'])

export const templateMethods = mapActions('templates', ['getTemplates', 'createTemplate', 'getTemplate', 'updateTemplate','deleteTemplate', 'getTemplatesStore', 'updateTemplateStore', 'createTemplateStore']);

export const journeyMethods = mapActions('journeys', ['getJourneys', 'createJourney', 'getJourney', 'updateJourney', 'deleteJourney', 'getIsBlockValid', 'getBlockText', 'getJourneysVars']);

export const broadcastMethods = mapActions('broadcasts', ['getBroadcasts', 'createBroadcast', 'getBroadcast', 'updateBroadcast','deleteBroadcast', 'generateMessage','testMessage', 'countCustomerBroadcast', 'getBroadcastExecutions'])

export const interactionMethods = mapActions('interactions', ['getInteractions', 'createInteraction', 'updateInteraction','deleteInteraction'])

export const goalMethods = mapActions('goals', ['getGoals', 'createGoal', 'updateGoal','deleteGoal'])

export const strategyMethods = mapActions('strategies', ['getCustomStrategies', 'createCustomStrategy', 'updateCustomStrategy','deleteCustomStrategy', 'getCustomStrategy'])

export const personalShopperMethods = mapActions('personalShoppers', ['getPersonalShoppers', 'createPersonalShopper', 'updatePersonalShopper','deletePersonalShopper', 'getPersonalShopper', 'trainPersonalShopper'])

/* GO LOYALTY  */
export const loyaltyProgramsMethods = mapActions('loyalty/loyaltyPrograms', ['getLoyaltyPrograms', 'createLoyaltyProgram', 'updateLoyaltyProgram','deleteLoyaltyProgram', 'getLoyaltyProgram'])
export const loyaltyLevelsMethods = mapActions('loyalty/levels', ['getLoyaltyLevels', 'createLoyaltyLevel', 'updateLoyaltyLevel','deleteLoyaltyLevel', 'getLoyaltyLevel'])
/* GO LOYALTY  */
