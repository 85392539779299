import CommonService from '../../services/common'
import i18n from "../../i18n";

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  uploadMedia({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
    })
  },

  // eslint-disable-next-line no-unused-vars
  getNews ({ commit },params) {
    return new Promise((resolve, reject) => { 
      CommonService.getNews(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getRecommendationStrategies({ commit }){
    return [
      {
        id: "user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_user_reco'),
      },
      {
        id: "collaborative",
        name: i18n.t('personalizations.experience_variable_strategy_collaborative')
      },
      {
        id: "similarity",
        name: i18n.t('personalizations.experience_variable_strategy_similarity'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing']
      },
      {
        id: "viewed_together",
        name: i18n.t('personalizations.experience_variable_strategy_viewed_together'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "purchased_together",
        name: i18n.t('personalizations.experience_variable_strategy_purchased_together'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "popularity",
        name: i18n.t('personalizations.experience_variable_strategy_popularity')
      },
      {
        id: "recently_viewed",
        name: i18n.t('personalizations.experience_variable_strategy_recently_viewed')
      },
      {
        id: "recently_viewed_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_viewed_similar')
      },
      {
        id: "liked_similar",
        name: i18n.t('personalizations.experience_variable_strategy_liked_similar')
      },
      {
        id: "recently_purchased_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_purchased_similar')
      },
      {
        id: "user_affinity",
        name: i18n.t('personalizations.experience_variable_strategy_user_affinity')
      },
      {
        id: "complete_the_look",
        name: i18n.t('personalizations.experience_variable_strategy_complete_the_look'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "complementary",
        name: i18n.t('personalizations.experience_variable_strategy_complementary'),
        pages_restricted: ['checkout', 'category','home','product_listing','search']
      },
      {
        id: "top-sellers",
        name: i18n.t('personalizations.experience_variable_strategy_top_sellers'),
      },
      {
        id: "most-viewed",
        name: i18n.t('personalizations.experience_variable_strategy_most_viewed'),
      },
      {
        id: "last_viewed_category",
        name: i18n.t('personalizations.experience_variable_strategy_last_category_viewed'),
      },
      {
        id: "popularity_category",
        name: i18n.t('personalizations.experience_variable_strategy_category_popularity'),
        pages_restricted: ['cart','checkout','home','product_listing','search']
      },
      {
        id: "recently_searched_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_similar'),
      },
      {
        id: "recently_searched_popular",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_popular'),
      },
      {
        id: "recently_searched_user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_user_reco'),
      },
      {
        id: "recently_searched_user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_user_reco'),
      },
      {
        id: "top_searched_products",
        name: i18n.t('personalizations.experience_variable_strategy_top_searched_products'),
      },
      
    ]
  },
  
  // eslint-disable-next-line no-unused-vars
  getFonts({commit}){
    return [
      {
        label: 'Alex Brush',
        value: 'Alex Brush',
        url: 'https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap'
      },
      {
        label: "Andale Mono",
        value: "andale mono,times"
      },
      {
        label: 'Anton',
        value: 'Anton',
        url: 'https://fonts.googleapis.com/css2?family=Anton&display=swap'
      },
      {
        label: "Arial",
        value: "arial,helvetica,sans-serif"
      },
      {
        label: "Arial Black",
        value: "arial black,avant garde,arial"
      },
      {
        label: 'Arvo',
        value: 'Arvo',
        url: 'https://fonts.googleapis.com/css2?family=Arvo&display=swap'
      },
      {
        label: 'Bitter',
        value: 'Bitter',
        url: 'https://fonts.googleapis.com/css2?family=Bitter:wght@600&display=swap'
      },
      {
        label: "Book Antiqua",
        value: "book antiqua,palatino"
      },
      {
        label: 'Bungee',
        value: 'Bungee',
        url: 'https://fonts.googleapis.com/css2?family=Bungee&display=swap'
      },
      {
        label: 'Cabin',
        value: 'Cabin',
        url: 'https://fonts.googleapis.com/css?family=Cabin:400,700&display=swap'
      },
      {
        label: 'Caveat Brush',
        value: 'Caveat Brush',
        url: 'https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap'
      },
      {
        label: 'Chau Philomene One',
        value: 'Chau Philomene One',
        url: 'https://fonts.googleapis.com/css2?family=Chau+Philomene+One&display=swap'
      },
      {
        label: 'Cinzel Decorative',
        value: 'Cinzel Decorative',
        url: 'https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap'
      },
      {
        label: "Comic Sans MS",
        value: "comic sans ms,sans-serif"
      },
      {
        label: "Courier New",
        value: "courier new,courier"
      },
      {
        label: 'Crimson Text',
        value: 'Crimson Text',
        url: 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700&display=swap'
      },
      {
        label: 'Epilogue',
        value: 'Epilogue',
        url: 'https://fonts.googleapis.com/css2?family=Epilogue:wght@500&display=swap'
      },
      {
        label: 'Federo',
        value: 'Federo',
        url: 'https://fonts.googleapis.com/css2?family=Federo&display=swap'
      },
      {
        label: 'Fredoka One',
        value: 'Fredoka One',
        url: 'https://fonts.googleapis.com/css2?family=Federo&display=swap'
      },
      {
        label: "Georgia",
        value: "georgia,palatino"
      },
      {
        label: 'Great Vibes',
        value: 'Great Vibes',
        url: 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'
      },
      {
        label: "Impact",
        value: "impact,chicago"
      },
      {
        label: "Helvetica",
        value: "helvetica,sans-serif"
      },
      {
        label: 'Lato',
        value: 'Lato',
        url: "https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"
      },
      {
        label: 'Lobster Two',
        value: 'Lobster Two',
        url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700&display=swap"
      },
      {
        label: 'Montserrat',
        value: 'Montserrat',
        url: "https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap"
      },
      {
        label: 'Nunito',
        value: 'Nunito',
        url: "https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap"
      },
      {
        label: 'Old Standard TT',
        value: 'Old Standard TT',
        url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700&display=swap"
      },
      {
        label: 'Open Sans',
        value: 'Open Sans',
        url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap"
      },
      {
        label: 'Oswald',
        value: 'Oswald',
        url: "https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap"
      },
      {
        label: 'Pacific',
        value: 'Pacific',
        url: "https://fonts.googleapis.com/css?family=Pacifico&display=swap"
      },
      {
        label: 'Playfair Display',
        value: 'Playfair Display',
        url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap'
      },
      {
        label: 'Quicksand',
        value: 'Quicksand',
        url: "https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap"
      },
      {
        label: 'Raleway',
        value: 'Raleway',
        url: "https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap"
      },
      {
        label: 'Roboto',
        value: 'Roboto',
        url: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
      },
      
      {
        label: 'Rubik',
        value: 'Rubik',
        url: "https://fonts.googleapis.com/css?family=Rubik:400,700&display=swap"
      },
      {
        label: 'Source Sans Pro',
        value: 'Source Sans Pro',
        url: "hhttps://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap"
      },
      {
        label: "Symbol",
        value: "symbol"
      },
      {
        label: "Tahoma",
        value: "tahoma,arial,helvetica,sans-serif"
      },
      {
        label: "Terminal",
        value: "terminal,monaco"
      },
      {
        label: "Times New Roman",
        value: "times new roman,times"
      },
      {
        label: "Trebuchet MS",
        value: "trebuchet ms,geneva"
      },
      {
        label: 'Ubuntu',
        value: 'Ubuntu',
        url: "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap"
      },
      {
        label: "Verdana",
        value: "verdana,geneva"
      },
    ]
  }
}