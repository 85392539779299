<template>
  <div class="container">
      <EmailEditor ref="emailEditor" v-on:load="editorLoaded" locale="es" :projectId="241050" />
  </div>
</template>

<script>
  import { EmailEditor } from 'vue-email-editor'
  
  export default {
    components: {
      EmailEditor
    },
    data(){
      return {
        editor: null
      }
    }

    ,methods: {
      editorLoaded() {
        this.editor = this.$refs.emailEditor.editor;
        this.$refs.emailEditor.editor.setBodyValues({
        contentWidth: '600px' // Cambia el ancho del cuerpo a 600px
      });
      },
      setTemplateValue(values){
        if(values.design){
          this.editor.loadDesign(values.design);
        }else{
          this.editor.loadDesign({});
        }
      },
      getTemplatesValues(){
        return new Promise((resolve) => {
          this.$refs.emailEditor.editor.exportHtml((data) => {
            resolve({
              type: 'email',
              html: data.html,
              design: data.design,
              variables: [],
              groups: []
            });
          });
        });
      },
    }
  }
</script>
<style scoped>
  .container {
    height: 600px;
    display: flex;
    flex-direction: column;
  }

  .container > * {
    flex: 1;
  }
</style>