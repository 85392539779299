import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createBroadcast = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/broadcasts`,
    payload,
    config
  )
}

const _getBroadcasts = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/broadcasts?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/broadcasts`


  return Request.get(
    url,
    config
  )
}

const _deleteBroadcast = broadcastId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/broadcasts/${broadcastId}`, config)
}

const _getBroadcast = broadcastId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/broadcasts/${broadcastId}`, config)
}
const _updateBroadcast = (broadcast) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/broadcasts/${broadcast._id}`, broadcast, config)
}

const _generateMessage = (payload) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/broadcasts/generate-message`,
    payload,
    config
  )
  
} 

const _testMessage = (payload) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/broadcasts/test-message`,
    payload,
    config
  )
}

const _countCustomerBroadcast = (payload) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/broadcasts/count-customers-broadcast`,
    payload,
    config
  )
  
}

const _getBroadcastExecutions = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/broadcasts/${params.broadcastId}/executions?${params.q}`, config)
}


export default {
  updateBroadcast: _updateBroadcast,
  deleteBroadcast : _deleteBroadcast, 
  getBroadcast : _getBroadcast, 
  getBroadcasts : _getBroadcasts,
  createBroadcast : _createBroadcast,
  generateMessage: _generateMessage,
  testMessage: _testMessage,
  countCustomerBroadcast: _countCustomerBroadcast,
  getBroadcastExecutions: _getBroadcastExecutions
}
