import Handlebars from 'handlebars';

Handlebars.registerHelper('recommendations', function (context, options) {
  let result = '';

  if (Array.isArray(context)) {
    for (let i = 0; i < context.length; i++) {
      const data = context[i];
      result += options.fn(data);
    }
  } else if (typeof context === 'object') {
    for (const key in context) {
      if (Object.prototype.hasOwnProperty.call(context, key)) {
        const data = context[key];
        result += options.fn(data);
      }
    }
  }

  return result;
});

Handlebars.registerHelper('ne', function (a, b) {
  return a != b;
});

Handlebars.registerHelper('eq', function (a, b) {
  return a == b;
});

Handlebars.registerHelper('isYes', function (value) {
  return value === 'Yes' ? 'true' : 'false';
});

Handlebars.registerHelper('divide', function(a, b) {
  return a / b;
});

function renderEmailTemplate(template,variablesArray,data){
  if (!data) {
    data = {
      session: {}
    }
  }

  let variablesObject = {
    gs_templateId: data.gs_templateId || 'templateId'
  };
    for(let variable of variablesArray) {
      variablesObject[variable.name] = variable.value;
    }
    variablesObject = Object.assign({}, variablesObject, data.customer || {});

  if (template.includes("#recommendations")) {
    const recommendations = data.gs_recommendations;
    if (recommendations) {
      variablesObject['recommendations'] = recommendations;
      variablesObject['items'] = recommendations;
    }
  }

  template = transformEmailTemplate(template);
  const templateComplete = Handlebars.compile(template);

  return templateComplete(variablesObject);
}
function renderTemplate(template, variablesArray, data) {
  
  if (!data) {
    data = {
      session: {

      }
    }
  }
  
  template = transformTemplate(template);

  // Convert the variables array into an object
  let variablesObject = {};
  for (let variable of variablesArray) {
    if (!['gs_recoStrategy', 'gs_recoCount'].includes(variable.type.id)) {

      try {
        if (variable.type.id == 'list') {
          
          variablesObject[variable.name] = JSON.stringify(variable.value);
        } else {
          variablesObject[variable.name] = variable.value;
        }
      } catch (e) {
        
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }



  if (data.hasRecommendationsBlockFlag) {
    // add variables to items
    const itemsObj = addItemVariables(variablesArray, data.recommendations);
    variablesObject = Object.assign({}, variablesObject, itemsObj);
  }

  if (data.session) {
    variablesObject['session'] = data.session;
  }
  variablesObject['gs_variantId'] = data.variantId;

  if (data.gs_recommendations) {
    data.gs_recommendations.forEach(r=> r.description = stripHTML(r.description));
    variablesObject['gs_recommendations'] = JSON.stringify(data.gs_recommendations);
  }

  // Compile the template with Handlebars
  let compiledTemplate = Handlebars.compile(template, {
    helpers: {
      'eq': function (arg1, arg2) {
        return arg1 === arg2;
      }
    }
  });

  // Render the template with the variables
  let renderedTemplate = compiledTemplate(variablesObject);

  return renderedTemplate;
}

function stripHTML(html) {
  // eslint-disable-next-line no-control-regex
  return html?.replace(/[\u0000-\u001F\u007F]+/g, "");
}

function transformTemplate(template) {

  template = template.replace(/{{gs_recommendations}}/g, '{{{gs_recommendations}}}');

  const transformation1 = template.replace(/\{\{(?!\{)(?!#|\/|isYes)(.+?)\}\}/g, function (match, group1) {
    // Check if group1 contains a dot
    if (group1.includes('.')) {
      return match; // Return the original match if it's a "deep" variable
    }

    if (!group1.trim().startsWith('isYes')) {
      return `{{[${group1}]}}`;
    }

    return match; // Return the original match if it starts with "isYes"
  });

  // Remove comments enclosed by <!-- ignore_start --> and <!-- ignore_end -->
  const regex = /<!-- ignore_start -->[\s\S]*?<!-- ignore_end -->/g;
  const transformation2 = transformation1.replace(regex, '');

  return transformation2;
}

function transformEmailTemplate(template) {

  const transformation1 = template.replace(/\{\{(?!#|\/|isYes)(.+?)\}\}/g, function (match, group1) {
    // Check if group1 contains a dot
    if (group1.includes('.')) {
      return match; // Return the original match if it's a "deep" variable
    }
    if (!group1.trim().startsWith('isYes')) {
      return `{{[${group1}]}}`;
    }
    return match; // Return the original match if it starts with "isYes"
  });

  const regex = /<!-- ignore_start -->[\s\S]*?<!-- ignore_end -->/g;
  const transformation2 = transformation1.replace(regex, '');

  return transformation2;
}

function addItemVariables(variables, recommendations) {
  let items = recommendations;

  variables.forEach(function (variable) {
    if (variable.type.id == 'product_property') {
      for (let i = 0; i < items.length; i++) {
        if (!['image'].includes(variable.value.id)) {
          items[i][variable.name] = items[i][variable.value.id];
        } else if (variable.value.id == 'image') {
          if (items[i] && variable && variable.name && items[i].imgs && items[i].imgs.length > 0) {
            items[i][variable.name] = items[i].imgs[0].url;
          }
        }
      }
    }
  })

  return {
    items
  }
}

export default {
  renderTemplate,
  renderEmailTemplate
}