import Request from './baseRequest'

const integrationTypes = [
	{
		name: "gopersonal",
		icon: "https://go-shops.nyc3.digitaloceanspaces.com/uploads/2023/10/81dc16ff-6432-4b8e-ab07-055fbf1bacd6.svg",
		type: 'go_shops_web',
		group: 'go_shops',
		fields: [{
			key: "name",
			name: "Name",
			type: 'text'
		},
		{
			key: "sessionExpirationTime",
			name: "Session Expiration Time (hs)",
			type: 'number',
			value: 24
		}
		]
	},
	{
		name: "Magento",
		icon: "https://go-live.fra1.digitaloceanspaces.com/uploads/2023/08/41218dcc-9e9d-4c8b-8d1c-dac637c6c910.svg",
		type: 'magento',
		group: 'feed',
		fields: [
			{
				key: "url",
				name: "URL",
				type: 'url'
			},
			{
				key: "oauth",
				name: "Use OAuth",
				type: 'checkbox'
			},
			{
				key: "token",
				name: "Token",
				type: 'password'
			},
			{
				key: "consumer_key",
				name: "Consumer Key",
				type: 'text'
			},
			{
				key: "consumer_secret",
				name: "Consumer Secret",
				type: 'password'
			},
			{
				key: "access_token",
				name: "Access Token Key",
				type: 'text'
			},
			{
				key: "access_token_secret",
				name: "Access Token Secret",
				type: 'password'
			},
			{
				key: "store",
				name: "Store",
				type: 'text'
			},
			{
				key: "use_live_shop",
				name: "Use Live Shopping",
				type: "checkbox"
			},
			{
				key: "only_simple_products",
				name: "Use only simple products",
				type: 'checkbox'
			},
		]
	},
	{
		name: "WooCommerce",
		icon: "https://gs-prod-public.goshops.ai/uploads/2024/01/70d1fdf8-fd33-4711-a9e3-299a6d54fa21.png",
		type: 'woocomerce',
		group: 'feed',
		fields: [
			{
				key: "url",
				name: "URL",
				type: 'url'
			},
			{
				key: "consumer_key",
				name: "consumer key",
				type: 'text'
			},
			{
				key: "consumer_secret",
				name: "consumer secret",
				type: 'password'
			},
		]
	},
	{
		name: "Email",
		icon: "https://go-live.fra1.digitaloceanspaces.com/uploads/2023/08/31dde42b-5738-4266-a073-2b701d38d95f.svg",
		type: 'email',
		group: 'channel',
		fields: [
			{
				key: "domain",
				name: "Domain",
				type: 'url'
			},
			{
				key: "displayName",
				name: "Display Name",
				type: 'text'
			},
			{
				key: "email",
				name: "Email",
				type: 'email'
			},
		]
	},
	{
		name: "SMTP Server",
		icon: "https://cdn.iconscout.com/icon/premium/png-256-thumb/mail-server-3161352-2633770.png",
		type: 'smtp_email',
		group: 'channel',
		fields: [
			{
				key: "address",
				name: "Address",
				type: 'text'
			},
			{
				key: "port",
				name: "Port",
				type: 'number'
			},
			{
				key: "smtp_login",
				name: "SMTP Login",
				type: 'text'
			},
			{
				key: "smtp_password",
				name: "SMTP Password",
				type: 'password'
			},
		]
	},
	{
		name: "Twilio SMS",
		icon: "https://go-live.fra1.digitaloceanspaces.com/uploads/2023/08/92bad63c-3363-4182-b16c-ba9571e53b46.svg",
		type: 'sms',
		group: 'channel',
		fields: [
			{
				key: "accountSid",
				name: "Account SID",
				type: 'text'
			},
			{
				key: "authToken",
				name: "Auth Token",
				type: 'password'
			},
			{
				key: "fromNumber",
				name: "From Number",
				type: 'text'
			},
			{
				key: "fromName",
				name: "From Name",
				type: 'text'
			},
		]
	},
	{
		name: "Go BOT",
		icon: "https://go-live.fra1.digitaloceanspaces.com/uploads/2023/08/3df3b748-aef5-43cb-8f9a-bb6edcea3e5e.svg",
		type: 'go_chatbot',
		group: 'other',
		fields: [
			{
				key: "use_personal_shopper",
				name: "Use Personal Shopper",
				type: "checkbox"
			},
		]
	},
	{
		name: "App Push Notification",
		icon: "https://go-shops.nyc3.digitaloceanspaces.com/uploads/2023/10/bc42c2eb-11e9-43dc-b342-498159fe3612.svg",
		type: 'app_push',
		group: 'channel',
		fields: [
			{
				key: "server_key",
				name: "Firebase Server key (json)",
				type: "file",
				format: 'application/json'
			},
		]
	},
	{
		name: "Feed",
		icon: "https://cdn1.iconfinder.com/data/icons/business-programming/512/time-1024.png",
		type: 'feed_url',
		group: 'feed',
		fields: []
	},
	{
		name: "Fenicio",
		icon: "https://pbs.twimg.com/profile_images/1502387029484744704/BhjScjED_400x400.jpg",
		type: 'fenicio',
		group: 'feed',
		fields: [
			{
				key: "url",
				name: "URL",
				type: 'url'
			},
		],
		hidden_sync: {
			products: false,
			customers: true,
			interactions: true
		}
	},
	{
		name: "VTEX",
		icon: "https://gs-devtest-public.goshops.ai/uploads/2024/03/cf339ce4-8e69-4778-96db-cc980dbfdc65.jpeg",
		type: 'vtex',
		group: 'feed',
		fields: [
			{
				key: "url",
				name: "URL",
				type: 'url'
			},
			{
				key: "appKey",
				name: "App Key",
				type: 'text'
			},
			{
				key: "appToken",
				name: "App Token",
				type: 'password'
			},
		],
	},
	{
		name: "Web Push Notification",
		icon: "https://gs-prod-public.goshops.ai/uploads/2024/05/3474997c-5e3d-4a39-a56c-af130e693289.png",
		type: 'web_push',
		group: 'channel',
		fields: [
			{
				key: "server_key",
				name: "Firebase Server key (json)",
				type: "file",
				format: 'application/json'
			},
		]
	},
	{
		name: "FTP Customers",
		icon: "https://gs-prod-public.goshops.ai/uploads/2024/09/f1cf8bfa-8767-45ab-8ace-1964e7381aaf.png",
		type: 'ftp_customers',
		group: 'other',
		fields: [
			{
				key: "host",
				name: "Host",
				type: 'text'
			},
			{
				key: "fpt_username",
				name: "Username",
				type: 'text'
			},
			{
				key: "fpt_password",
				name: "Password",
				type: 'password'
			},
			{
				key: "secure",
				name: "Use secure",
				type: "checkbox"
			},
			{
				key: "folder",
				name: "Folder",
				type: "text"
			},
			{
				key: "file_regexp",
				name: "File Name (regexp)",
				type: "text"
			},
			{
				key: "parse",
				name: "Parse Function",
				type: "code"
			},
		]
	},
	
]

export { integrationTypes }
const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createIntegration = payload => {

	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.post(`${window.config.VUE_APP_BACKEND}/api/integrations`,
		payload,
		config
	)
}

const _getIntegrations = params => {

	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	const url = params ? `${window.config.VUE_APP_BACKEND}/api/integrations?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/integrations`

	return Request.get(url,
		config
	)
}

const _deleteIntegration = integrationId => {

	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.delete(`${window.config.VUE_APP_BACKEND}/api/integrations/${integrationId}`, config)
}

const _updateIntegration = (integration) => {
	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.put(`${window.config.VUE_APP_BACKEND}/api/integrations/${integration._id}`, integration, config)
}

const _updateGoShopsIntegration = (autChannel) => {
	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.put(`${window.config.VUE_APP_BACKEND}/api/channel-auths/${autChannel._id}`, autChannel, config)
}

const _createGoShopsIntegration = (autChannel) => {
	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.post(`${window.config.VUE_APP_BACKEND}/api/channel-auths`, autChannel, config)
}

const _getGoShopsIntegration = (autChannelId) => {
	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.get(`${window.config.VUE_APP_BACKEND}/api/channel-auths/${autChannelId}`, config)
}

const _deleteGoShopsIntegration = integrationId => {

	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.delete(`${window.config.VUE_APP_BACKEND}/api/channel-auths/${integrationId}`, config)
}

const _ssoGoShopsIntegration = integrationId => {

	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}

	return Request.get(`${window.config.VUE_APP_BACKEND}/api/integrations/${integrationId}/sso`, config)
}


export default {
	createIntegration: _createIntegration,
	updateIntegration: _updateIntegration,
	deleteIntegration: _deleteIntegration,
	getIntegrations: _getIntegrations,
	updateGoShopsIntegration: _updateGoShopsIntegration,
	createGoShopsIntegration: _createGoShopsIntegration,
	getGoShopsIntegration: _getGoShopsIntegration,
	deleteGoShopsIntegration: _deleteGoShopsIntegration,
	ssoGoShopsIntegration: _ssoGoShopsIntegration
}
