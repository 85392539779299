
import ContentService from '../../services/contents'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createContent ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      ContentService.createContent(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating campaign'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getContents ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ContentService.getContents(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getContent ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ContentService.getContent(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteContent ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ContentService.deleteContent(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateContent ({ commit }, content) {

    return new Promise((resolve, reject) => { 
      ContentService.updateContent(content).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateExperience ({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.updateExperience(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateVariant ({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.updateVariant(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  createVariant ({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.createVariant(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  createExperience({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.createExperience(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getVariantHistory ({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.getVariantHistory(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  generateContentFromImage ({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      ContentService.generateContentFromImage(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  } 
}
