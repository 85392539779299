
import LoyaltyProgramsService from '../../../services/loyalty/loyaltyPrograms'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createLoyaltyProgram ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      LoyaltyProgramsService.createLoyaltyProgram(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating loyalty program'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getLoyaltyPrograms ({ commit },params) {

    return new Promise((resolve, reject) => { 
      LoyaltyProgramsService.getLoyaltyPrograms(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getLoyaltyProgram ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      LoyaltyProgramsService.getLoyaltyProgram(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteLoyaltyProgram ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      LoyaltyProgramsService.deleteLoyaltyProgram(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateLoyaltyProgram ({ commit }, loyaltyProgram) {

    return new Promise((resolve, reject) => { 
      LoyaltyProgramsService.updateLoyaltyProgram(loyaltyProgram).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}