<script>
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list";
import Draggable from "vuedraggable"

import {
	commonMethods,
  projectMethods,
  strategyMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	data() {
		return {
			submitted:false,
			variables:[],
      isDrawerOpen:false,
      variable:{
        type:null,
        options:[]
      },
      groups:[],
      options:[],
      types:[
        {id:'text', name: this.$t('personalizations.experience_variable_type_text')},
        {id:'number', name: this.$t('personalizations.experience_variable_type_number')},
        {id:'color', name: this.$t('personalizations.experience_variable_type_color')},
        {id:'image', name: this.$t('personalizations.experience_variable_type_image')},
        {id:'options', name: this.$t('personalizations.experience_variable_type_options')},
        {id:'product_property', name: this.$t('personalizations.experience_variable_type_product_property')},
        {id:'product_property_catalog', name: this.$t ('personalizations.experience_variable_type_options_property_product')},
        {id:'list', name: this.$t ('personalizations.experience_variable_type_list')},
        {id:'font', name: this.$t('personalizations.experience_variable_type_font')},
        {id:'date', name: this.$t('personalizations.experience_variable_type_date')},
        {id:'datetime', name: this.$t("personalizations.experience_variable_type_datetime")},
      ],
      isUpdate:false,
      reservedVars:[
        "gs_variantId",
        "gs_experienceId",
        "gs_personalizationId",
        "#recommendations items",
        "/recommendations",
        "gs_recoStrategy",
        "gs_recoCount",
        "gs_recoStrategyAffinity",
        "gs_recommendations",
        "gs_recoStrategyCustom"
      ],
      recoStrategies:[],
      customStrategies:[],
      productProperties:[
        {
          id:"name",
          name: this.$t('personalizations.experience_variable_product_property_name')
        },
        {
          id:"price",
          name: this.$t('personalizations.experience_variable_product_property_price')
        },
        {
          id:"image",
          name: this.$t('personalizations.experience_variable_product_property_image')
        },
        {
          id:"description",
          name: this.$t('personalizations.experience_variable_product_property_description')
        },
        {
          id:"category",
          name: this.$t('personalizations.experience_variable_product_property_category')
        },
        {
          id:"sku",
          name: "SKU"
        },
        {
          id:"id",
          name: "Id"
        },
        {
          id:"active",
          name: this.$t('personalizations.experience_variable_product_property_active')
        },
      ],
      empty_config:{
        "title" : this.$t('personalizations.variables_empty'),
        "subtitle" : this.$t('personalizations.variables_empty_subtitle'),
        "buttonText" : this.$t('personalizations.new_variable'),
        icon: "fa-cubes"
      },
      pageLoaded:false,
      nameOK:true,
      openedInputIndex : null,
      showListOptions: false,
      selectedVariable: null,
      showNewOption:false,
      newOption:[],
      selectedValue:-1,
      showNewGroup: false,
      selectedGroup: {},
      auxGroup: null,
      list_types: [
        {value: 'text', text: this.$t('personalizations.experience_variable_type_text')},
        {value: 'image', text: this.$t('personalizations.experience_variable_type_image')},
        {value: 'calculate_affinity', text: this.$t("personalizations.experience_variable_type_affinity_text")}
      ],
      listOptionName: '',
      listOptionType: null,
      openedListOptionInputIndex: null,
      fonts:[] 
    };
	},
  created(){
      this.debounceVariables = _debounce(this.onUpdateVariable.bind(this), 1000);
    },
    computed:{
      filteredWithGroups: {
        get: function() {
          
          let withGroups = this.groups;
          const vars = this.variables.filter(v=>v.type.id != "product_property");
          
          this.groups.forEach((g)=>g.variables = [])
          if(withGroups.filter(g=>g.id == -1).length ==0){
            withGroups.push({
              "name" : "Sin Asignar",
              id:-1,
              variables:[]
            })
          }

          vars.forEach(v=>{
            if(!v.group){
              if(withGroups.filter(g=>g.id == -1)[0].variables.filter(va=> va.name == v.name).length==0){
                withGroups.filter(g=>g.id == -1)[0].variables.push(v);
              }
            }else{
              if(withGroups.filter(g=>g.id == v.group).length > 0){
                if(!withGroups.filter(g=>g.id == v.group)[0].variables)
                withGroups.filter(g=>g.id == v.group)[0].variables = [v];
                else{
                  if(withGroups.filter(g=>g.id == v.group)[0].variables.filter(va=> va.name == v.name).length==0)
                    withGroups.filter(g=>g.id == v.group)[0].variables.push(v);
                }
              }else{
                v.group = null;
                if(withGroups.filter(g=>g.id == -1)[0].variables.filter(va=> va.name == v.name).length==0){
                  withGroups.filter(g=>g.id == -1)[0].variables.push(v);
                }
              }
              
            }
          })

          if(withGroups.filter(g=>g.id == -1)[0].variables.length == 0){
            withGroups = withGroups.filter(g=>g.id != -1);
          }
          
          return withGroups;
        },
        set(newVal){
          const orderIndices = {};
          newVal.forEach((element, index) => {
            orderIndices[element.id] = index;
          });

          this.groups.sort((a, b) => {
            const indexA = orderIndices[a.id];
            const indexB = orderIndices[b.id];

            if (indexA === undefined && indexB === undefined) {
              return 0; // Leave items in their original order if not found in orderArray
            } else if (indexA === undefined) {
              return 1; // Move items not found in orderArray to the end
            } else if (indexB === undefined) {
              return -1; // Move items not found in orderArray to the end
            } else {
              return indexA - indexB; // Compare based on their indices in orderArray
            }
          });
        }
      },
      filteredVariables : {
        get: function() {
          return this.variables.filter(v=>v.type.id != "product_property")
        },
        set(newVal){
          const orderIndices = {};
          newVal.forEach((element, index) => {
            orderIndices[element.name] = index;
          });

          this.variables.sort((a, b) => {
            const indexA = orderIndices[a.name];
            const indexB = orderIndices[b.name];

            if (indexA === undefined && indexB === undefined) {
              return 0; // Leave items in their original order if not found in orderArray
            } else if (indexA === undefined) {
              return 1; // Move items not found in orderArray to the end
            } else if (indexB === undefined) {
              return -1; // Move items not found in orderArray to the end
            } else {
              return indexA - indexB; // Compare based on their indices in orderArray
            }
          });
        }
      }
    },
  validations: {
    variable: {
      name: { required },
      type: { required },
      placeholder: {
        required: requiredIf((model)=> { return model.type?.id == 'text' ||  model.type?.id == 'number' ||  model.type?.id == 'image' }),
      },
      min: {
        required: requiredIf((model)=> { return model.type?.id == 'number' }),
      },
      max: {
        required: requiredIf((model)=> { return model.type?.id == 'number' }),
      },
      value: {
        required: requiredIf((model)=> { return model.type?.id == 'product_property' }),
      },
      options: {
        required: requiredIf((model)=> { return model.type?.id == 'list' || model.type?.id == 'options'}),
      },
      
    },
    selectedGroup : {
      name: { required },
    }
  },
  methods: {
    ...commonMethods,
    ...projectMethods,
    ...strategyMethods,

    /*async loadRecoStrategies(){
      this.recoStrategies = await this.getRecommendationStrategies();
    },*/

    loadCustomStrategies(){

      const params = {
				q : `where[status]=1&where[project]=${localStorage.getItem("current_project")}&limit=50&select=_id name pageType`
			}

      this.getCustomStrategies(params).then((result)=>{
        if(result&& result.data){
          this.customStrategies= result.data;

          if(this.pageType && this.pageType != 'any'){
            this.customStrategies = this.customStrategies.filter(cs=> cs.pageType == 'any' || cs.pageType == this.pageType);
          }
        }
      })
    },
    async loadProjectStrategies(){
      this.recoStrategies = await this.getRecommendationStrategies();

      if(this.pageType){
        this.recoStrategies = this.recoStrategies.filter(s => (!s.pages_restricted || s.pages_restricted.length == 0) || (s.pages_restricted.length > 0 && s.pages_restricted.filter(p=> p === this.pageType).length == 0) )
      }
      
      this.recoStrategies.push({
        name : this.$t('personalizations.experience_variable_strategy_custom'),
        id: "custom_strategy"
      })
      this.getProject(localStorage.getItem('current_project'))
				.then((res) => {
          if(res && res.data && res.data.strategies && res.data.strategies.length > 0){
            this.recoStrategies = this.recoStrategies.concat(res.data.strategies)
          }
        })
					.catch(() => {
          
					})
    },
    onStrategySelected(strategy){
      if(strategy?.id== 'user_affinity'){

        this.variables = this.variables.filter(v=> v.type.id != 'gs_recoStrategyCustom');

        const affinityItem = {
          name: this.$t("personalizations.experience_variable_strategy_affinity"),
          type: {id:'gs_recoStrategyAffinity'},
          placeholder: this.$t("personalizations.experience_variable_strategy_affinity_placeholder"),
          tooltip: this.$t("personalizations.experience_variable_strategy_affinity_tooltip"),
          group:-1
        }

        const index = this.variables.findIndex(v => v.type?.id === 'gs_recoStrategy');
        
        if (index !== -1) {
          const insertIndex = index + 1;
          this.variables.splice(insertIndex, 0, affinityItem);
        }
      }else if(strategy?.id == 'custom_strategy'){
        this.variables = this.variables.filter(v=> v.type.id != 'gs_recoStrategyAffinity');
        const customItem = {
          name: this.$t('personalizations.experience_variable_strategy_custom'),
          type: {id:'gs_recoStrategyCustom'},
          placeholder: this.$t('personalizations.experience_variable_strategy_custom_placeholder'),
          tooltip: this.$t('personalizations.experience_variable_strategy_custom_tooltip'),
          group:-1
        }

        const index = this.variables.findIndex(v => v.type?.id === 'gs_recoStrategy');
        
        if (index !== -1) {
          const insertIndex = index + 1;
          this.variables.splice(insertIndex, 0, customItem);
        }
      }
      else{
          this.variables = this.variables.filter(v=> v.type.id != 'gs_recoStrategyAffinity' &&  v.type.id != 'gs_recoStrategyCustom');
      }

      if(this.variables.filter(variable=>variable.type?.id=='gs_recoStrategy').length > 0 );
        this.variables.filter(variable=>variable.type?.id=='gs_recoStrategy')[0].value = strategy;


        this.debounceVariables();
    },
    onFontSelected(v){
      v.value = v.auxValue.value;
      this.onVariableValueUpdated(v);
    },
    isVariableNameOk(variable){
      if(this.reservedVars.filter(v=> v.toUpperCase() == variable.name.toUpperCase()).length > 0)
        return false;

      return this.isUpdate ? this.variables.filter(v=>v.name == variable.name && v.id != variable.id).length == 0 : this.variables.filter(v=>v.name == variable.name).length == 0;
    },
    getVariables(){
      return this.variables;
    },
    getGroups(){
      return this.groups;
    },
    setVariables(variables){
      this.variables = variables || [];
      this.pageLoaded = true;
    },
    setGroups(groups){
      this.groups = groups || [];
    },
    addTag (tag) {
      this.variable.options.push(tag);
    },
    addListTag (tag) {
      this.variable.options.push(tag);
    },
		closeDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    onNewVariable(variable){

      this.filteredWithGroups.forEach((el,index) => {
        el.variables.forEach((v,indexVar)=>{
          if(document.getElementById(`settings_${index}_${indexVar}`)?.style){
            document.getElementById(`settings_${index}_${indexVar}`).style.display = 'none';
          }
        })
      });
      
      this.isUpdate = false;
      this.nameOK = true;
      this.submitted = false;
      
      this.variable = variable || {
        type:null,
        options:[]
      }

      if(variable && variable.group){
        this.auxGroup = this.groups.filter(g=>g.id == variable.group).length > 0 ? this.groups.filter(g=>g.id == variable.group)[0] : null 
      }
      this.isDrawerOpen = true;

      this.listOptionName= '',
      this.listOptionType = null;
    },
    onVariableSettingClicked(v){
      this.filteredVariables.forEach((el,index) => {
        if(document.getElementById(`settings_${index}`)){
          document.getElementById(`settings_${index}`).style.display = 'none';
        }
      });
      this.isUpdate = true;
      this.nameOK = true;
      this.variable = v;
      this.isDrawerOpen = true;

      if(this.variable.group){
        this.auxGroup = this.groups.filter(g=>g.id == this.variable.group).length > 0 ? this.groups.filter(g=>g.id ==this.variable.group)[0] : null 
      }

    },
    onUpdateVariable(){
      this.$emit('updateVariables', this.variables);
      this.$emit('updateGroups', this.groups);
    },
    onSaveVariableClicked(){
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.variable.$invalid) {
        return;
      } 

      this.nameOK = this.isVariableNameOk(this.variable);
      
      if(!this.nameOK)
        return;

      this.variable.name.trim();
      
      if(this.variable.type.id == 'list'){
        if(!this.variable.value) this.variable.value = [];
      }
      if(!this.isUpdate){
        this.variable.id = Math.floor(Math.random() * 10000) + 1;
        this.variables.push(this.variable);
      }else{
          if(!this.variable.id){
            if(this.variable.group !=null && this.variable.group != -1 && this.variables.filter(v=>v.name == this.variable.name).length > 0 && this.groups.filter(g=> g.id == this.variable.group).length > 0){
              this.variables.filter(v=>v.name == this.variable.name)[0].group = this.variable.group;
              this.groups.forEach((g)=> g.variables = []);
            }else{
              this.variables.filter(v=>v.name == this.variable.name)[0].group = null;
              this.groups.forEach((g)=> g.variables = []);
            }
          }else{
            if(this.variable.group !=null && this.variable.group != -1 && this.variables.filter(v=>v.id == this.variable.id).length > 0 && this.groups.filter(g=> g.id == this.variable.group).length > 0){
              this.variables.filter(v=>v.id == this.variable.id)[0].group = this.variable.group;
              this.groups.forEach((g)=> g.variables = []);
            }else{
              this.variables.filter(v=>v.id == this.variable.id)[0].group = null;
              this.groups.forEach((g)=> g.variables = []);
            }
          }
        
      }      
      this.variable = {
        type:null,
        options:[],
      }
      this.isDrawerOpen = false;
      this.submitted = false;

      this.auxGroup = null;
      this.$emit('updateVariables', this.variables);
      this.$emit('updateGroups', this.groups);
    },
    onUploadImageClicked(i){
      this.openedInputIndex = i;
      this.$nextTick(() => {
        this.$refs[`imagePicker_${i}`][0].click();
      });
      
    },

    onImagePicker(ev,variable){
      
      var file = ev.target.files[0];
      ev.target.value = '';

      if(file){
        this.uploadMedia(file)
				.then((res) => {
          this.$set(variable, 'value', res.data.url);
          this.$emit('updateVariables', this.variables);
            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("personalizations.upload_error"),title: "Media"});
					})
			}
    },

    onUploadListOptionImageClicked(index){
      this.openedListOptionInputIndex = index;
      this.$nextTick(() => {
        this.$refs[`list_option_imagePicker_${index}`][0].click();
      });
    },
    onListOptionImagePicker(ev,option){
      
      var file = ev.target.files[0];
      ev.target.value = '';

      if(file){
        this.uploadMedia(file)
				.then((res) => {
          this.$set(option, 'value', res.data.url);
            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("personalizations.upload_error"),title: "Media"});
					})
			}
    },
    handleMouseOver(variable,groupId) {
      this.filteredWithGroups.forEach((el,index) => {
        el.variables.forEach((v,indexVar)=>{
          if(document.getElementById(`settings_${index}_${indexVar}`)?.style){
            document.getElementById(`settings_${index}_${indexVar}`).style.display = 'none';
          }
        })
      });
      

      if( document.getElementById(`settings_${groupId}_${variable}`)?.style){
        document.getElementById(`settings_${groupId}_${variable}`).style.display = 'block';
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleMouseLeave(variable, groupId) {
      this.filteredWithGroups.forEach((el,index) => {
        el.variables.forEach((v,indexVar)=>{
          if(document.getElementById(`settings_${index}_${indexVar}`)?.style){
            document.getElementById(`settings_${index}_${indexVar}`).style.display = 'none';
          }
        })
      });
    },
    onRemoveVariableClicked(){
      this.variables = this.variables.filter((v)=>v.name != this.variable.name);
      this.groups.forEach((g)=>{
        g.variables = [];
      })
      
      this.isDrawerOpen = false;
      this.submitted = false;

      this.onUpdateVariable();
    },
    onNewOptionClicked(v){
      if(!v.value){
        v.value = [];
      }
      this.selectedVariable = v;
      this.newOption = [];
      
      this.selectedVariable.options.forEach((o)=>{
        let option = {};
        option.name = o,
        option.value = ''

        this.newOption.push(option);
      })
      
      this.showNewOption = true;
      this.selectedValue = -1;
    },
    onAddNewOptionClicked(variable){
      if(!variable.options)
        variable.options = [];

      variable.options.push({
        value: '',
        label: ''
      })
    },
    onAddNewListOptionClicked(variable){
      if(!variable.options)
        variable.options = [];

      if(this.listOptionType && this.listOptionName){
        variable.options.push({
          name: this.listOptionName,
          type: this.listOptionType
        })

        this.listOptionName = '';
        this.listOptionType = null;
      }    
  },
    onVariableValueUpdated(v){
      if(this.variables.filter(variable=>variable.name == v.name).length > 0 );
        this.variables.filter(variable=>variable.name == v.name)[0].value = v.value;

      this.debounceVariables();
    },
    onOptionSelected(v){
      v.value = v.valueAux.value;
      if(this.variables.filter(variable=>variable.name == v.name).length > 0 );
        this.variables.filter(variable=>variable.name == v.name)[0].value = v.value;

      this.debounceVariables();
    },
    async onConfirmAddOptionsClicked(){
      const loader = this.$loading.show();
      const va = this.variables.filter(v=> v.name == this.selectedVariable.name);
      const obj = {}
      for (const key of this.newOption) {
            obj[key.name.name] = key.value;
            if(key.name.type == 'calculate_affinity'){
              const payload = {
                project: localStorage.getItem('current_project'),
                concept: key.value 
              }
              const res = await this.getEmbeddings(payload)
              obj.embeddings = res.embedding || [];
            }
      }

      if(this.selectedValue!= -1){
        va[0].value[this.selectedValue] = obj;
      }else{
        va[0].value.push(obj);
      }
      this.selectedValue = -1;
      this.showNewOption = false
      
      loader.hide();

    },
    onShowListOptionsClicked(v){
      this.selectedVariable = v;
      this.showListOptions = true;
    },
    onRemoveValueFromList(i){
      this.selectedVariable.value.splice(i,1);
    },
    _onEditValueFromList(i){
      this.selectedValue = i
    },
    onEditValueFromList(item, index){
      
      this.newOption = [];
      
      this.selectedVariable.options.forEach((o)=>{
        let option = {};
        option.name = o,
        option.value = item[o.name]

        this.newOption.push(option);
      })
      
      this.showNewOption = true
      this.selectedValue = index;
    },
    onSaveValueFromList(){
      this.selectedValue = -1;
    },
    onNewGroup(){
      this.showNewGroup = true;
      this.selectedGroup = {
        name: '' 
      }
      this.submitted = false
    },
    editGroup(groupIndex){
      this.selectedGroup = this.filteredWithGroups[groupIndex];

      this.showNewGroup = true;
    },
    removeGroup(groupIndex){
      this.groups.splice(groupIndex,1);
      this.$emit('updateGroups', this.groups);
    },
    log(evt, groupIndex){
      if(evt.added){
        this.variables.filter(v=> v.name == evt.added.element.name)[0].group = this.groups[groupIndex].id;
        this.groups[groupIndex].variables = []
      }else if(evt.removed){
        this.groups[groupIndex].variables = []
      }
    },
    onConfirmNewGroupClicked(){
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.selectedGroup.$invalid) {
        return;
      } 

      if(!this.selectedGroup.id){
        this.selectedGroup.id = Math.floor(Math.random() * 10000) + 1;
        this.selectedGroup.variables = [];
        this.groups.push(this.selectedGroup);
      }
      
      this.showNewGroup = false;
    },
    onGroupSelected(){
      if(this.auxGroup && this.auxGroup.id && this.auxGroup.id!=-1){
        this.variable.group = this.auxGroup.id; 
      }else{
        this.variable.group = null;
      }
    },
    async loadFonts(){
      this.fonts = await this.getFonts();
    },
	},
  mounted() {
    this.loadProjectStrategies();
    this.loadCustomStrategies();
    this.loadFonts();
	},
  components:{Multiselect, EmptyList, Draggable },
	props: {
		experience : Object,
		tabIndex: Number,
    affinity_Types: Array,
    categories: Array,
    pageType: String
	},
	emits: ['updateVariables', 'updateGroups'],
	watch: {
    tabIndex:{
      immediate: true,
      handler(newVal){
        if(newVal){
					this.index = newVal;
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <EmptyList :config="empty_config" v-if="pageLoaded && filteredVariables.length == 0 && !isDrawerOpen" @onButtonClicked="onNewVariable()" class="mt-3"/>
    <div class="row" v-if=" filteredVariables.length > 0 && !isDrawerOpen">
      <div class="d-flex flex-row-reverse w-100 text-end">
        <button class="btn btn-link btn-md text-end mb-1" @click="onNewVariable(null)" v-if="filteredVariables.length > 0">+ {{ $t('personalizations.new_variable') }}</button>
        <button class="btn btn-link btn-md text-end mb-1" @click="onNewGroup(null)" v-if="filteredVariables.length > 0">+ {{ $t('personalizations.experience_variable_new_group') }}</button>
      </div>
      
      <div class="col vars-panel">
        <draggable v-model="filteredWithGroups" :group="'filteredWithGroups'"  :forceFallback="true" class="accordion mt-2" role="tablist">
        <div v-for="(group, groupIndex) in filteredWithGroups" :key="group.id" class="mb-2">
          <div v-b-toggle="'accordion-' + groupIndex" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 38px;" role="tab">
            <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{ `${group.name} (${group.variables.length})` }}</button>
            <i class="ml-auto text-end bx bx-pencil interact me-1" v-on:click="editGroup(groupIndex)" v-if="group.id!=-1"></i>
            <i class="ml-auto text-end bx bx-trash interact me-1" v-on:click="removeGroup(groupIndex)" v-if="group.id!=-1"></i>
          </div>
            <b-collapse :id="`accordion-${groupIndex}`" :visible="groupIndex==0" role="tabpanel" class="mt-2">
            <draggable
              :forceFallback="true"
              v-model="group.variables"
              group="group"
              @change="(evt)=>log(evt,groupIndex)">
            <div class="row mb-3 pe-0" v-for="(v,i) in group.variables" :key="v.name" @mouseover="handleMouseOver(i,groupIndex)" @mouseleave="handleMouseLeave(i, groupIndex,)">
                <div class="col">
                  <label class="w-100 mb-1">{{ v.name }}  <i class="bx bxs-cog float-end pt-1 text-end interact" :id="`settings_${groupIndex}_${i}`" v-on:click="onVariableSettingClicked(v)"></i> <i class="mdi mdi-help-circle-outline font-size-14 next-right-help" v-b-popover.hover.right="v.tooltip" v-if="v.tooltip"></i> </label>
                  <b-form-input type="text" class="form-control form-control" v-model="v.value" :placeholder="v.placeholder" v-if="v.type?.id=='text'" @input="onVariableValueUpdated(v)"/>
                  <b-form-input type="number" :min="v.min" :max="v.max" class="form-control" v-model="v.value" :placeholder="v.placeholder" v-if="v.type?.id=='number'" @input="onVariableValueUpdated(v)"/>
                  <b-form-input type="color" class="form-control-color " v-model="v.value" v-if="v.type?.id=='color'" @input="onVariableValueUpdated(v)"/>
                  <div class="input-group" v-if="v.type?.id=='image'">
                    <b-form-input type="text" class="form-control " v-model="v.value" :placeholder="v.placeholder" @input="onVariableValueUpdated(v)" />
                    <button class="btn btn-primary" type="button" id="inputGroupFileAddon03" @click="onUploadImageClicked(i)"> <i class="bx bx-upload"/> </button>
                    <input
                      :ref="`imagePicker_${i}`"
                      @change="onImagePicker($event,v)"
                      accept="image/*"
                      class="form-control "
                      style="display: none;"
                      type="file"/>
                  </div>
                  <b-form-input type="date" class="form-control" v-model="v.value" :placeholder="v.placeholder" v-if="v.type?.id=='date'" @input="onVariableValueUpdated(v)"/>
                  <b-form-input type="datetime-local" class="form-control" v-model="v.value" :placeholder="v.placeholder" v-if="v.type?.id=='datetime'" @input="onVariableValueUpdated(v)"/>
                  <multiselect
                        :options="v.options" 
                        v-model="v.valueAux"
                        selectLabel=""
                        deselectLabel=""
                        :placeholder="v.placeholder"
                        @input="onOptionSelected(v)"
                        track-by="value"
                        label="label"
                        v-if="v.type?.id=='options'"
                      />

                      <multiselect
                        :options="affinity_Types" 
                        v-model="v.value"
                        selectLabel=""
                        deselectLabel=""
                        track-by="column_name"
                        label="name"
                        :placeholder="v.placeholder"
                        @input="onVariableValueUpdated(v)"
                        v-if="v.type?.id=='product_property_catalog'"
                      />
                      
                    <multiselect
                      :options="recoStrategies" 
                      v-model="v.value"
                      selectLabel=""
                      deselectLabel=""
                      track-by="id"
                      label="name"
                      :allow-empty="false"
                      :placeholder="v.placeholder"
                      v-if="v.type?.id=='gs_recoStrategy'"
                      @input="onStrategySelected"
                    />


                    <multiselect
                      :options="affinity_Types" 
                      v-model="v.value"
                      selectLabel=""
                      deselectLabel=""
                      track-by="column_name"
                      label="name"
                      :placeholder="v.placeholder"
                      v-if="v.type?.id=='gs_recoStrategyAffinity'"
                      :allow-empty="false"
                      openDirection="bottom"
                      @input="onVariableValueUpdated(v)"
                    />

                    <multiselect
                      :options="customStrategies" 
                      v-model="v.value"
                      selectLabel=""
                      deselectLabel=""
                      track-by="_id"
                      label="name"
                      :placeholder="v.placeholder"
                      v-if="v.type?.id=='gs_recoStrategyCustom'"
                      :allow-empty="false"
                      openDirection="bottom"
                      @input="onVariableValueUpdated(v)"
                    />
                    
                    <multiselect
                      :options="fonts"
                      v-model="v.auxValue"
                      selectLabel=""
                      deselectLabel=""
                      track-by="label"
                      label="label"
                      :placeholder="v.placeholder"
                      v-if="v.type?.id=='font'"
                      :allow-empty="false"
                      openDirection="bottom"
                      @input="onFontSelected(v)"
                    />

                    <b-form-input type="number" min="1" max="100" class="form-control" v-model="v.value" :placeholder="v.placeholder" v-if="v.type?.id=='gs_recoCount'" @input="onVariableValueUpdated(v)"/>

                    <div v-if="v.type?.id=='list'">
                      <span class="badge bg-primary" v-on:click="onShowListOptionsClicked(v)">{{ v.value?.length ? `${v.value?.length} Opciones` : '0 opciones'}}</span> 
                    </div>
                  </div>
                </div>
            </draggable>
            </b-collapse>
        </div>
    </draggable>
      </div>
      
    </div>
    <transition name="drawer">
      <div v-if="isDrawerOpen" class="drawer bg-secondary bg-soft" style="overflow-y: scroll;">
        <!-- Drawer content -->
        <div class="row mb-3">
            <h4 class="col-10">{{ !isUpdate ? this.$t('personalizations.experience_variable_new_variable') : this.$t('personalizations.experience_variable_edit_variable')}}</h4>
            <i class="fa fa-times col-2 interact text-end font-size-18" v-on:click="closeDrawer"></i>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="mb-0">{{$t('personalizations.experience_variable_name')}}</label>
            <b-form-input 
              type="text" 
              :placeholder="$t('personalizations.experience_variable_name_placeholder')" 
              v-model="variable.name" 
              class="form-control"
              :class="{'is-invalid': submitted && ($v.variable.name.$error || !nameOK),}">
            </b-form-input>
            <div v-if="submitted && !$v.variable.name.required"  class="invalid-feedback">
              {{$t('personalizations.experience_variable_name_required')}}
            </div>
            <div v-if="submitted && !nameOK"  class="invalid-feedback">
              {{$t('personalizations.experience_variable_name_exists')}}
            </div>
            
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="mb-0">{{$t('personalizations.experience_variable_select_type')}}</label>
            <multiselect
                :options="types" 
                v-model="variable.type"
                track-by="id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :placeholder="$t('personalizations.experience_variable_select_type_placeholder')"
                :class="{'is-invalid': submitted && $v.variable.type.$error,}"
              />
              <div v-if="submitted && !$v.variable.type.required"  class="invalid-feedback">
                {{$t('personalizations.experience_variable_select_type_required')}}
              </div>
          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id != 'product_property'">
          <div class="col">
            <label class="mb-0">Tooltip</label>
            <b-form-input 
              type="text" 
              :placeholder="$t('personalizations.experience_variable_select_tooltip_placeholder')" 
              v-model="variable.tooltip" 
              class="form-control">
            </b-form-input>
          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id == 'text' || variable.type?.id == 'number' || variable.type?.id == 'options' || variable.type?.id == 'image' || variable.type?.id == 'product_property_catalog'">
          <div class="col">
            <label class="mb-0">Placeholder</label>
            <b-form-input 
              type="text" 
              :placeholder="$t('personalizations.experience_variable_placeholder_placeholder')" 
              v-model="variable.placeholder" 
              class="form-control"
              :class="{'is-invalid': submitted && $v.variable.placeholder.$error,}">
            </b-form-input>
            <div v-if="submitted && !$v.variable.placeholder.required"  class="invalid-feedback">
              {{$t('personalizations.experience_variable_placeholder_required')}}
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id == 'number'">
          <div class="col-6">
            <label class="mb-0">Min</label>
            <b-form-input 
              type="number" 
              :placeholder="$t('personalizations.experience_variable_min_placeholder')" 
              v-model="variable.min" 
              class="form-control"
              :class="{'is-invalid': submitted && $v.variable.min.$error,}">
            </b-form-input>
            <div v-if="submitted && !$v.variable.min.required"  class="invalid-feedback">
              {{$t('personalizations.experience_variable_min_required')}}
            </div>
          </div>
          <div class="col-6">
            <label class="mb-0">Max</label>
            <b-form-input 
              type="number" 
              :placeholder="$t('personalizations.experience_variable_max_placeholder')" 
              v-model="variable.max" 
              class="form-control"
              :class="{'is-invalid': submitted && $v.variable.max.$error,}">
            </b-form-input>
            <div v-if="submitted && !$v.variable.max.required"  class="invalid-feedback">
              {{$t('personalizations.experience_variable_max_required')}}
              </div>
          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id == 'options'">
          <div class="col">
            <div class=" d-flex d-flex-inline align-items-baseline">
              <label class="mb-2">{{$t('personalizations.experience_variable_options')}}</label>
              <button class="btn btn-sm btn-link text-end flex-fill" @click="onAddNewOptionClicked(variable)"><i class="bx bx-plus interact"></i>Opción</button>
            </div>
            
            <div class=" d-flex d-flex-inline align-items-baseline" v-for="(option, index) in variable.options" :key="index">
              <p class="text-muted mb-0">Label</p>
              <b-form-input placeholder="" class="form-control form-control-sm ms-1 me-2" type="text" v-model="option.label"></b-form-input>
              <p class="text-muted mb-0">Value</p>
              <b-form-input placeholder="" class="form-control form-control-sm ms-1 me-1" type="text" v-model="option.value"></b-form-input>
              <i class="bx bx-trash interact me-1"></i>
            </div>
          <!--  <multiselect
                :options="options" 
                v-model="variable.options"
                selectLabel=""
                deselectLabel=""
                :placeholder="$t('personalizations.experience_variable_options_placeholder')" 
                :taggable="true"
                :multiple="true"
                @tag="addTag"
                :class="{'is-invalid': submitted && $v.variable.options.$error,}"
              />
              <div v-if="submitted && !$v.variable.options.required"  class="invalid-feedback">
                {{$t('personalizations.experience_variable_options_required')}}
              </div>-->

          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id == 'list'">
          <div class="col">
            <label class="mb-0">{{$t('personalizations.experience_variable_options')}}</label>
            <multiselect
                :options="options" 
                v-model="variable.options"
                selectLabel=""
                deselectLabel=""
                :placeholder="$t('personalizations.experience_variable_options_placeholder')" 
                :taggable="false"
                :multiple="true"
                track-by="id"
                label="name"
                :class="{'is-invalid': submitted && $v.variable.options.$error,}"
              />
              <div class="row mt-1">
                <div class="col pe-0">
                  <b-form-input placeholder="Name" class="form-control form-control-sm ms-1 me-2" type="text" v-model="listOptionName"></b-form-input>
                </div>
                <div class="col pe-0">
                  <b-form-select placeholder="Type" class="form-control form-control-sm ms-1 me-2" :options="list_types" v-model="listOptionType"></b-form-select>
                </div>
                <div class="col-auto d-flex align-items-center">
                  <button class="btn btn-sm btn-link text-end flex-fill" @click="onAddNewListOptionClicked(variable)"><i class="bx bx-plus interact"></i></button>
                </div>
              </div>
              <div v-if="submitted && !$v.variable.options.required"  class="invalid-feedback">
                {{$t('personalizations.experience_variable_options_required')}}
              </div>
          </div>
        </div>
        <div class="row mb-3" v-if="variable.type?.id == 'product_property'">
          <div class="col">
            <label class="mb-0">{{$t('personalizations.experience_variable_product_property')}}</label>
            <multiselect
                :options="productProperties" 
                v-model="variable.value"
                track-by="id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :placeholder="$t('personalizations.experience_variable_product_property_placeholder')" 
                :class="{'is-invalid': submitted && $v.variable.value.$error,}"
              />
              <div v-if="submitted && !$v.variable.value.required"  class="invalid-feedback">
                {{$t('personalizations.experience_variable_product_property_required')}}
              </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="mb-0">{{$t('personalizations.experience_variable_type_group')}}</label>
            <multiselect
                :options="groups" 
                v-model="auxGroup"
                track-by="id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :placeholder="$t('personalizations.experience_variable_group_placeholder')" 
                @input="onGroupSelected"
              />
          </div>
        </div>
        <div class="row">
          <div class="text-center col">
            <button type="button" v-on:click="onRemoveVariableClicked" class="btn btn-sm btn-danger mt-3 mb-3 me-2" v-if="isUpdate" >{{$t('common.delete')}}</button>
            <button type="button" v-on:click="onSaveVariableClicked" class="btn btn-sm btn-primary mt-3 mb-3" >{{$t('common.save')}}</button>
          </div>
        </div>
      </div>
    </transition>
    <b-modal v-model="showListOptions" id="modal-center" :title="selectedVariable?.name"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')" :size="selectedVariable?.options?.length > 2 ? 'xl' : ''">
      <div class="table-responsive">
        <label class="interact text-end d-block" v-on:click="onNewOptionClicked(selectedVariable)">+Valor</label>
        <table class="table">
          <thead class="table-light">
              <tr>
                  <th v-for="(column, index) in selectedVariable?.options" :key="index"> {{column.name}}</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(item, index) in selectedVariable?.value" :key="index">
                  <td v-for="(column, indexColumn) in selectedVariable?.options" :key="indexColumn">
                    <p v-if="column.type!= 'image'" class="mb-0">
                      {{item[column.name]}}
                    </p>
                    <img :src="item[column.name]" v-if="column.type== 'image'" height="60"/>
                  </td>
                  <td class="px-0" style="width: 30px;"> <i class="bx bx-pencil interact text-success" v-on:click="onEditValueFromList(item,index)" v-if="selectedValue!=index"></i> <i class="bx bx-trash text-danger" v-on:click="onRemoveValueFromList(index)"></i></td>
              </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <b-modal v-model="showNewOption" id="modal-center" :title="selectedVariable?.name" @ok="onConfirmAddOptionsClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
      <div>
        <div v-for="(option,index) in newOption" :key="index" class="mb-3">
          <label class="mb-0">{{ option.name.name }}</label>
          <input class="form-control" v-model="option.value" v-if="option.name.type == 'text'">
          <input class="form-control" v-model="option.value" v-if="option.name.type == 'calculate_affinity'">
          <div class="input-group" v-if="option.name.type == 'image'">
            <b-form-input type="text" class="form-control " v-model="option.value" />
              <button class="btn btn-primary" type="button" id="inputGroupFileAddon03" @click="onUploadListOptionImageClicked(index)"> <i class="bx bx-upload"/> </button>
                <input
                  :ref="`list_option_imagePicker_${index}`"
                  @change="onListOptionImagePicker($event,option)"
                  accept="image/*"
                  class="form-control "
                  style="display: none;"
                  type="file"/>
              </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="showNewGroup" id="modal-center" :title="selectedGroup.id ? 'Edit Group' : $t('personalizations.experience_variable_new_group')" @ok.prevent="onConfirmNewGroupClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
      <div>
        <label class="mb-0">Nombre</label>
        <b-form-input v-model="selectedGroup.name" :class="{'is-invalid': submitted && $v.selectedGroup.name.$error,}"></b-form-input>
        <div v-if="submitted && !$v.selectedGroup.name.required"  class="invalid-feedback">
          {{$t('personalizations.experience_variable_name_required')}}
        </div>
      </div>
    </b-modal>
	</div>
	
</template>

<style>
.drawer {
  width: 90%;
  height: 600px;
  padding: 20px;
  background-color: red;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  position: absolute;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.3s ease-in-out;
}

.drawer-enter,
.drawer-leave-to {
  transform: translateX(100%);
}

.interact{
  cursor: pointer;
}

</style>

<style>
.vars-panel{
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.next-right-help {
    cursor: pointer;
    top: 5px;
    right: 5px;
}

</style>