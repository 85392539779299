import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createContent = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/contents`,
    payload,
    config
  )
}

const _getContents = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/contents?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/contents`


  return Request.get(
    url,
    config
  )
}

const _deleteContent = contentId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/contents/${contentId}`, config)
}

const _getContent = contentId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/contents/${contentId}?populate[0][path]=targets`, config)
}
const _updateContent = (content) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/contents/${content._id}`, content, config)
}

const _updateExperience = (payload) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/contents/${payload.personalizationId}/target/${payload.experience._id}`, payload.experience, config)
}

const _updateVariant = (payload) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  
  return Request.put(`${window.config.VUE_APP_BACKEND}/api/contents/${payload.personalizationId}/target/${payload.experience}/variant/${payload.variant._id}`, payload.variant, config)
}

const _createVariant = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/contents/${payload.personalizationId}/target/${payload.experience}/variant`,
    payload.variant,
    config
  )
}


const _createExperience = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/contents/${payload.personalizationId}/target`,
    payload.experience,
    config
  )
}

const _getVariantHistory = payload => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/contents/${payload.personalizationId}/target/${payload.experienceId}/variant/${payload.variantId}/history?populate[0][path]=createdBy`, config)
}

const _generateContentFromImage = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/contents/generate-from-image`,
    payload,
    config
  )
}
export default {
  createVariant: _createVariant,
  createExperience: _createExperience,
  updateVariant: _updateVariant,
  updateExperience: _updateExperience,
  updateContent: _updateContent,
  deleteContent : _deleteContent, 
  getContent : _getContent, 
  getContents : _getContents,
  createContent : _createContent,
  getVariantHistory: _getVariantHistory,
  generateContentFromImage: _generateContentFromImage
}
