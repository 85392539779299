<script>
import Multiselect from "vue-multiselect";
import { Picker } from 'emoji-mart-vue-2'
import CustomWebBuilder from "@/components/journeys/configs/email-builder.vue"
import EmailBuilder from "@/components/journeys/configs/email-unlayer-builder.vue";
import Drawer from "vue-simple-drawer";
import DynamicContent from "@/components/journeys/configs/dynamic-vars.vue"
import Integrations  from "@/components/projects/integrations";
import Store from "@/components/personalizations/templates-store.vue"
import AITextGenerator  from "@/components/journeys/ai-text-generator";
import TestChannel from '@/components/broadcasts/test-channel.vue';
import SimpleBar  from "simplebar-vue";
import { required } from "vuelidate/lib/validators";

import templateHandlebar from '../../../helpers/templates'

import {
	integrationMethods,
  templateMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect,
    Picker,
    CustomWebBuilder,
    EmailBuilder,
    Drawer,
    DynamicContent,
    Integrations,
    Store,
    AITextGenerator,
    TestChannel,
    SimpleBar
	},
	data() {
		return {
			submitted:false,
      showVariableModal: false,
      email:{
        
      },
      emailConfigs: [],
      subjectContent:'',
      preHeaderContent: '',
      showSubjectEmojis:false,
      showPreHeaderEmojis:false,
      categories:[],
      affinity_Types: [],
      items:[],
      bodyContent: '',
      openRightDrawer:false,
      value:'',
      showVarContent:false,
      from:null,
      showIntegrationsAvailable: false,
      integrations:[],
      showTemplatesAvailable: false,
      field:'',
      limit:50,
      openRightTestDrawer: false,
      testMessage: null,
      viewport :'desktop',
      mode: 'design',
      channel: 'email',
      isLocal: window.config.local || false,
      emailType: null,
      components: [],
      showComponentAddModal: false,
      showNewTemplate: false,
      template:{},
      useUtmParameters: false,
      utm: {
        utm_source: 'gopersonal',
        utm_medium : 'email',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      showEmojiPicker: false,
      emojiPickerTarget: null,
      emojiPickerStyle: {},
      
		};
	},
  validations: {
    template: {
        name: { required},
    }
  },
  computed: {},
  mounted() {
    window.addEventListener('click', this.onclickOutsideEmojisContainer);
    this.loadEmailIntegrations(true);
    this.loadEmailComponentsTemplates();
    
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onclickOutsideEmojisContainer)
  },
	methods: {
    ...integrationMethods,
    ...templateMethods,
    ...templateHandlebar,

    onEmailComponentClicked(component){
      // eslint-disable-next-line no-console
      console.log(component);
      navigator.clipboard.writeText(component.value?.exportedHTML || '');
      
      this.showComponentAddModal = true;  
    },
    loadEmailComponentsTemplates(){
      const params = {
        q: `whereObj={"project" : "${localStorage.getItem('current_project')}", "type": "email_block" }`
      }

      this.getTemplates(params).then((res)=>{
        this.components = res.data || [];
      })
    },
    loadEmailIntegrations(fromNew = false){
      const params = {
        q: `whereObj={"project" : "${localStorage.getItem('current_project')}", "$or":[{"type": "email"}, {"type": "smtp_email"}] }`
      }
      this.getIntegrations(params).then((integrations)=>{
        this.emailConfigs = integrations.data;
        this.emailConfigs.forEach((i)=>{
          i.label = `${i.name} | ${i.type=='email' ? i.data?.domain : i.data?.address}`
        })
        if(fromNew){
          this.from = this.emailConfigs.length > 0 ? this.emailConfigs[0] : null;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("integrations.get_integrations_error"),title:  this.$t("integrations.title") });
      }).finally(()=>{

      })
    },
    onTemplateClicked(template){
      this.showTemplatesAvailable = false;
      this.openRightDrawer = false;
      this.showVariableModal = true;
      
      if(template.value.design){
        this.emailType = 'drag';
        setTimeout(()=>{
            this.$refs.dragEmailBuilder.setTemplateValue(template.value || {});
          },1000)
      }else{
        this.emailType = 'html';
        setTimeout(()=>{
            this.$refs.customWebBuilder.setTemplateValue(template.value || {});
          },1000)
      }
    },

    rightDrawer() {
        this.openRightDrawer = !this.openRightDrawer;
    },
    rightTestDrawer(){
      this.openRightTestDrawer = !this.openRightTestDrawer;
    },
    onclickOutsideEmojisContainer(evt) {
      const emojiDiv = document.getElementById("emoji-picker");
      
      let targetEl = evt.target; 
      do {
        if (targetEl == emojiDiv) {
          return;
        }
        targetEl = targetEl.parentNode;
      } while (targetEl);
      
      this.showEmojiPicker = false;
    },
    handleEmojiClick(emoji) {
      if (this.emojiPickerTarget === 'subject') {
        this.handleSubjectEmojiClick(emoji);
      } else if (this.emojiPickerTarget === 'preHeader') {
        this.handlePreHeaderEmojiClick(emoji);
      }
      this.showEmojiPicker = false;
    },
    handleSubjectEmojiClick(detail) {
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(detail.native);
      div.appendChild(textNode);

      this.subjectContent = div.textContent;

      this.showSubjectEmojis = false;
    },
    handlePreHeaderEmojiClick(detail) {
      const div = this.$refs.editablePreHeaderDiv;
      const textNode = document.createTextNode(detail.native);
      div.appendChild(textNode);

      this.preHeaderContent = div.textContent;

      this.showPreHeaderEmojis = false;
    },
		getEmailConfig(){
      this.email.from = this.from._id;
      this.email.subject = this.subjectContent;
      this.email.preHeader = this.preHeaderContent;
      this.email.body = this.bodyContent;
      this.email.utm = this.useUtmParameters ? this.utm : null;
      return this.email; 
    },
    
    setEmailConfig(config){
      this.email = config || this.email;
      this.subjectContent = this.email.subject;
      this.preHeaderContent = this.email.preHeader;
      this.bodyContent = this.email.body;
      this.useUtmParameters = this.email.utm !=null;
      this.utm = this.email.utm? this.email.utm : this.utm;
      this.from = this.emailConfigs.filter(c=>c._id == this.email.from).length > 0 ? this.emailConfigs.filter(c=>c._id == this.email.from)[0] : this.emailConfigs.length > 0 ? this.emailConfigs[0] : null 
      this.$refs.editablePreHeaderDiv.innerHTML = this.preHeaderContent;
      this.$refs.editableDiv.innerHTML = this.subjectContent;

      if(this.bodyContent){
        setTimeout(()=>{
          this.loadPreview();
        },1000)
        
      }
    },

    onSetupBody(){
      this.showVariableModal = true;

      //let loader = this.$loading.show();
        setTimeout(()=>{
          //this.$refs.customWebBuilder.setTemplateValue(this.bodyContent || {});
          //loader.hide();
        },1000)
    },
    onSetupBodyClicked(){
      this.showTemplatesAvailable = true;
    },
    onVariableClicked(textToInsert){
      const editableDiv = this.$refs.editableDiv;
      
      // Get the current cursor position
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      const currentPosition = range.startOffset;
    if (!editableDiv.contains(range.startContainer)){
        return ;
      }
        
      // Insert the {{var}} text at the cursor position
      document.execCommand('insertText', false, textToInsert);

      // Restore the cursor position to the end of the inserted text
      range.setStart(editableDiv.firstChild, currentPosition + textToInsert.length);
      range.setEnd(editableDiv.firstChild, currentPosition + textToInsert.length);
      selection.removeAllRanges();
      selection.addRange(range);
          
    },
    onVariablePreHeaderClicked(){
      const editableDiv = this.$refs.editablePreHeaderDiv;
      
      // Get the current cursor position
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      const currentPosition = range.startOffset;

      const textToInsert = '{{var}}';

      if (!editableDiv.contains(range.startContainer)){
        return ;
      }
        
      // Insert the {{var}} text at the cursor position
      document.execCommand('insertText', false, textToInsert);

      // Restore the cursor position to the end of the inserted text
      range.setStart(editableDiv.firstChild, currentPosition + textToInsert.length);
      range.setEnd(editableDiv.firstChild, currentPosition + textToInsert.length);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    handleSubjectInput(event) {
      this.subjectContent = event.target.textContent;
    },
    handlePreHeaderInput(event){
      this.preHeaderContent = event.target.textContent;
    },
    async onConfirmBodyClicked(){
      if(this.emailType=='drag'){
        
        const values = await this.$refs.dragEmailBuilder.getTemplatesValues();
        // eslint-disable-next-line no-console
        console.log(values);
        this.bodyContent = values;
      }else{
        this.bodyContent = this.$refs.customWebBuilder.getTemplatesValues();
      }
      
      setTimeout(()=>{
        this.loadPreview();
      },1000)
        
      this.showVariableModal = false;
    },
    onEditClicked(){
      
      this.showVariableModal = true;
      if(this.bodyContent.design){
        this.emailType = 'drag';
        setTimeout(()=>{
            this.$refs.dragEmailBuilder.setTemplateValue(this.bodyContent || {});
          },1000)
      }else{
        this.emailType = 'html';
        setTimeout(()=>{
            this.$refs.customWebBuilder.setTemplateValue(this.bodyContent || {});
          },1000)
      }
    },
    onRemoveClicked(){
      this.bodyContent = null;
    },
    onPreHeaderVariableSelected(variable){
      const div = this.$refs.editablePreHeaderDiv;
      const textNode = document.createTextNode(`{{${variable.column_name}}}`);
      div.appendChild(textNode);

      this.preHeaderContent = div.textContent;

    },
    onSubjectVariableSelected(variable){
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(`{{${variable.column_name}}}`);
      div.appendChild(textNode);

      this.subjectContent = div.textContent;

    },
    onIntegrationsClicked(){
      this.$router.push({
          path: "/integrations",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    },
    onAddIntegrationClicked(){
      this.showIntegrationsAvailable = true
    },
    onConfirmNewIntegration(){
        this.showIntegrationsAvailable = false;
        this.loadEmailIntegrations(true);
    },
    validateEmailConfig(){
      this.submitted = true;
      let isOk = false;

      if(this.from && this.subjectContent && this.subjectContent !='' && this.preHeaderContent && this.preHeaderContent!='' && this.bodyContent)
        isOk = true;

      return isOk;
    },
    onSuggestedTextSelected(text){
      if(this.field == 'Subject'){
        this.subjectContent = text;
        const div = this.$refs.editableDiv;
        div.textContent = text;
      }else{
        this.preHeaderContent = text;
        const div = this.$refs.editablePreHeaderDiv;
        div.textContent = text;
      }
      this.openRightDrawer = false;
    },
    showTestChannel(){
      this.testMessage = this.getEmailConfig();
      this.openRightTestDrawer = true;
    },
    setViewport(viewport){
      this.viewport= viewport;
      this.$refs.customWebBuilder.onResolutionChanged(viewport);
    },
    setMode(mode){
      this.mode = mode;
      this.$refs.customWebBuilder.onSetMode(mode);
    },
    onBodyClicked(type){
      
      if(!this.showVariableModal){
        this.emailType = type;
        this.showVariableModal = true;
        if(type== 'html'){
         
          setTimeout(()=>{
            this.setMode('design');
            this.$refs.customWebBuilder.setTemplateValue(this.bodyContent || {});
          },1000)
        }else{
          setTimeout(()=>{
            this.setMode('design');
            this.$refs.dragEmailBuilder.setTemplateValue(this.bodyContent || {});
          },1000)
        }
        
      }
    },
    onSaveAsTemplateClicked(){
      this.template = {
        type: 'email',
        project: localStorage.getItem('current_project')
      }
      this.showNewTemplate = true;
    },
    async onConfirmNewTemplateClicked(){
        this.submitted = true;
        
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }
        
        if(this.emailType=='drag'){
          this.template.value = await this.$refs.dragEmailBuilder.getTemplatesValues();
        }else{
          this.template.value = this.$refs.customWebBuilder.getTemplatesValues();
        }
        this.template.project = localStorage.getItem('current_project');
        
        let loader = this.$loading.show();
        this.createTemplate(this.template).then(()=>{
          this.$notify({ type: 'success', text: this.$t('templates.create_template_success'),title:  'Templates' });
          this.showNewTemplate = false;
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('templates.create_template_error'),title: 'Templates' });
        }).finally(()=>{
          loader.hide();
        });
         
      },
      generatePreviewHTML() {
        return `${this.renderTemplate(this.bodyContent?.html, this.bodyContent?.variables, {
            gs_recommendations: []
          })}`
        },
        loadPreview(){
          const previewFrame = this.$refs.previewFrame;
          try{
            const content = this.generatePreviewHTML();
            previewFrame?.setAttribute('srcdoc', content);
          }catch(ex){
            // eslint-disable-next-line no-console
            console.log(ex);
          }
        },
        toggleEmojiPicker(target) {
      this.emojiPickerTarget = target;
      this.showEmojiPicker = !this.showEmojiPicker;
      
      if (this.showEmojiPicker) {
        this.$nextTick(() => {
          const button = event.target.closest('button');
          const rect = button.getBoundingClientRect();
          this.emojiPickerStyle = {
            position: 'fixed',
            top: `${rect.bottom}px`,
            left: `${rect.left}px`,
            zIndex: 1000,
          };
        });
      }
    },
  },

	props: {
		variant: Object
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row">
          <p class="small mb-3"> {{ $t('journeys.journey_workflow_components_channel_variants_email_config_subtitle') }} <a class="text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/broadcasts/nuevo-broadcast/email" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a></p>
      <div class="mb-3">
        <label for="phone" class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_email_config_from')}}</label>
        <Multiselect
          :options="emailConfigs"
          v-model="from"
          selectLabel="''"
          deselectLabel=""
          :placeholder="$t('journeys.journey_workflow_components_channel_variants_email_config_from_placeholder')"
          :multiple="false"
          :allowEmpty="false"
          track-by="_id" 
          label="label"
          open-direction="bottom"
          v-if="emailConfigs.length > 0">
        </Multiselect>
        <div class="card bg-info bg-gradient text-white mb-0">
          <div class="card-body p-2">
            <p class="card-text text-white" v-if="emailConfigs.length > 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_email_config_from_integrations')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onIntegrationsClicked">{{$t('journeys.journey_workflow_components_channel_variants_email_config_from_integrations2')}}</i> </p>
            <p class="card-text text-white" v-if="emailConfigs.length == 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_email_config_from_empty')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onAddIntegrationClicked">{{$t('journeys.journey_workflow_components_channel_variants_email_config_from_empty2')}}</i> {{$t('journeys.journey_workflow_components_channel_variants_email_config_from_empty3')}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label for="phone" class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_email_config_subject')}}</label>
        <div class="d-flex-inline input-group">
          <div class="form-control d-flex-inline" contentEditable="true" ref="editableDiv" id="editableDiv"  @input="handleSubjectInput">
          </div>
          <button class="btn btn btn-outline-primary btn-md" @mousedown.prevent @mouseup.prevent @click.stop="toggleEmojiPicker('subject')"> <i class="mdi mdi-emoticon-happy-outline"></i></button>
          <button class="btn btn btn-outline-primary btn-md" @click="openRightDrawer = true; field = 'Subject'; limit=60"> <i class="mdi mdi-wizard-hat"></i></button>
        </div>
        <div class="invalid-feedback" v-if="submitted && (!subjectContent || subjectContent == '')" style="display: block !important">
          {{$t('journeys.journey_workflow_components_channel_variants_email_config_subject_required')}}
        </div>
        <div class="d-flex-inline input-group">
          <DynamicContent @variableSelected="onSubjectVariableSelected"></DynamicContent>
          <label class="text-length">{{ `${subjectContent.length}/60` }}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label for="phone" class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_email_config_pre_header')}}</label>
        <div class="d-flex-inline input-group">
          <div class="form-control d-flex-inline" contentEditable="true" ref="editablePreHeaderDiv" @input="handlePreHeaderInput" >
          </div>
          <button class="btn btn btn-outline-primary btn-md" @mousedown.prevent @mouseup.prevent @click.stop="toggleEmojiPicker('preHeader')"> <i class="mdi mdi-emoticon-happy-outline"></i></button>
          <button class="btn btn btn-outline-primary btn-md" @click="openRightDrawer = true; field = 'Pre Header'; limit=150"> <i class="mdi mdi-wizard-hat"></i></button>
        </div>
        <div class="invalid-feedback" v-if="submitted && (!preHeaderContent || preHeaderContent == '')" style="display: block !important">
          {{$t('journeys.journey_workflow_components_channel_variants_email_config_pre_header_required')}}
        </div>
        <div class="d-flex-inline input-group">
          <DynamicContent @variableSelected="onPreHeaderVariableSelected"></DynamicContent>
          <label class="text-length">{{ `${preHeaderContent.length}/150` }}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label for="phone" class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_email_config_body')}}</label>
        <div v-if="bodyContent" class="email-container interact">
          <!--<iframe v-if="bodyContent" width="200" height="100" scrolling="no" frameborder="0" :srcdoc="bodyContent?.html" style="border-radius: 8px;">
          </iframe>-->
          <div class="text-center">
            <i class="mdi mdi-email-variant text-primary" style="font-size: 25px;"></i>
          </div>
          <div class="d-glex-inline text-center">
            <button class="btn btn-link" @click="onEditClicked"><i class="fas fa-edit text-success me-1"></i> {{$t('common.edit')}}</button>
            <button class="btn btn-link" @click="onRemoveClicked"><i class="fas fa-trash-alt text-danger me-1"> </i>{{$t('common.delete')}}</button>
          </div>
        </div>
        <div v-if="!bodyContent">
          <div class="row">
            <div class="col-4" v-on:click="onBodyClicked('drag')">
              <div class="mb-3">
                <label class="card-radio-label mb-2">
                  <input type="radio" name="currency" id="buycurrencyoption2" class="card-radio-input"/>
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-drawing font-size-24 text-primary align-middle me-2"></i>
                      <span> Drag & Drop</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-4" v-on:click="onBodyClicked('html')">
              <div class="mb-3">
                <label class="card-radio-label mb-2">
                  <input type="radio" name="currency" id="buycurrencyoption1" class="card-radio-input">
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-code-tags font-size-24 text-primary align-middle me-2"></i><span> Editor HTML</span>
                    </div>
                  </div>
                </label>
                <div>
              </div>
            </div>
          </div>
          
          <div class="col-4" v-on:click="showTemplatesAvailable=true">
            <div class="mb-3">
              <label class="card-radio-label mb-2">
                <input type="radio" name="currency" id="buycurrencyoption3" class="card-radio-input">
                <div class="card-radio">
                  <div>
                    <i class="mdi mdi-developer-board font-size-24 text-primary align-middle me-2"></i>
                    <span>Templates</span>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
          <!--<button class="btn btn-sm btn-link" @click="onSetupBodyClicked">{{$t('journeys.journey_workflow_setup')}}</button>-->
        </div>
        <div class="invalid-feedback" v-if="submitted && !bodyContent" style="display: block !important">
          {{$t('journeys.journey_workflow_components_channel_variants_email_config_body_required')}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label for="phone" class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_email_config_utm')}}</label>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <b-form-checkbox
              v-model="useUtmParameters"
              class="mb-3"
              plain>{{$t('journeys.journey_workflow_components_channel_variants_email_config_utm_use')}}</b-form-checkbox>
          </div>
        </div>
        <div class="row ms-2" v-if="useUtmParameters == true">
          <div class="row mb-2">
            <div class="col">
              <h6 class="small text-muted mb-0">UTM Source</h6>
              <b-form-input v-model="utm.utm_source"></b-form-input>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6 class="small text-muted mb-0">UTM Medium</h6>
              <b-form-input v-model="utm.utm_medium"></b-form-input>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6 class="small text-muted mb-0">UTM Campaign</h6>
              <b-form-input v-model="utm.utm_campaign"></b-form-input>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6 class="small text-muted mb-0">UTM Term</h6>
              <b-form-input v-model="utm.utm_term"></b-form-input>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6 class="small text-muted mb-0">UTM Content</h6>
              <b-form-input v-model="utm.utm_content"></b-form-input>
            </div>
          </div>
        </div>
      </div> 
    </div>
    </div>
      <div class="col d-flex flex-column">
        <label class="card-title">Preview</label>
        <span class="small">{{$t('journeys.journey_workflow_components_channel_variants_email_config_preview')}}</span> 
        
        <div class="mt-2" style="height: 500px" v-if="bodyContent">
          <!--<iframe src="devices/ios.html" style="width: 545px; height: 1181px; transform: scale(0.4); transform-origin: top;" /> -->
          <iframe ref="previewFrame" style="width: 100%; height: 812px; transform: scale(0.6); transform-origin: top;" /> 
        </div>
        <div v-else class="m-auto">
          <p>{{ $t('journeys.journey_workflow_components_channel_variants_email_config_preview_no_body') }}</p>
        </div>
      </div>  
      
    </div>
    <b-modal id="templates-modal" size="xl" v-model="showTemplatesAvailable" :title="$t('personalizations.experience_variant_start_with')" hide-footer no-close-on-backdrop>
        <Store :subType="'email'" :hideSubtypeFilter="true" :hideGoalsFilter="true" :showFromScratch="false" @selectStoreTemplate="onTemplateClicked" :showMyTemplates="true" :type="'email'"/>
      </b-modal>
    <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" :title="$t('integrations.new')" hide-footer>
        <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" :types="['email', 'smtp_email']" />
    </b-modal>

    <b-modal
        v-model="showVariableModal"
					id="modal-xl"
          size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable
          title="Body1"
          @ok.prevent="onConfirmBodyClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          
          <template #modal-header v-if="emailType=='html'">
            <div class="left-content" >
              <div class="btn-group">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" v-if="false" @click="setMode('preview')"  >
                  {{$t('personalizations.experience_variant_mode_preview')}}
                </button>
                <button type="button" :class="mode =='design' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('design')" >
                  {{$t('personalizations.experience_variant_mode_design')}}
                </button>
                <button type="button" :class="mode =='code' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('code')" >
                  {{$t('personalizations.experience_variant_mode_advanced')}}
                </button>
              </div>
            </div>
            <div class="center-content" v-if="mode=='design' && emailType=='html'">
              <div class="btn-group viewport-controls">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('desktop')"  >
                  <i class="mdi mdi-desktop-mac"></i>
                </button>
                <button type="button" :class="viewport =='tablet' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('tablet')">
                  <i class="mdi mdi-tablet-ipad"></i>
                </button>
                <button type="button" :class="viewport =='mobile' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('mobile')">
                  <i class="mdi mdi-cellphone-iphone"></i>
                </button>
              </div>
            </div>
            <div class="text-end w-100">
              <b-dropdown right menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="btn-outline-primary pt-2"
                variant="black">
                <template v-slot:button-content>
                  <i class="mdi mdi-plus me-1"></i>
                  <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                    {{ $t('journeys.journey_workflow_components_channel_variants_email_config_component') }}
                    <i class="mdi mdi-chevron-down"></i>
                  </span>
                </template>
                <SimpleBar style="max-height: 300px" v-if="components.length > 0">
                  <div v-for="c in components" :key="c._id">
                    <button class="dropdown-item py-3" @click="onEmailComponentClicked(c)">{{ c.name }}</button>
                  </div>
                </SimpleBar>
                <div v-else class="p-3">
                  <label class="small text-center">{{ $t('journeys.journey_workflow_components_channel_variants_email_config_no_components') }}</label>
                </div>
                <div class="dropdown-divider my-0"></div>
                  <a
                  class="dropdown-item text-primary text-center my-2"
                  href="javascript: void(0);">{{ $t('journeys.journey_workflow_components_channel_variants_email_config_new_component') }}</a>
              </b-dropdown>
        </div>
          </template>
          <template #modal-header v-else>
            <div class="left-content" >
              <h4>{{$t('journeys.journey_workflow_components_channel_variants_email_config_body')}}</h4>
            </div>
            <div class="text-end w-100">
              <b-dropdown right menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="btn-outline-primary pt-2"
                variant="black">
                <template v-slot:button-content>
                  <i class="mdi mdi-plus me-1"></i>
                  <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                    {{ $t('journeys.journey_workflow_components_channel_variants_email_config_component') }}
                    <i class="mdi mdi-chevron-down"></i>
                  </span>
                </template>

                <SimpleBar style="max-height: 300px" v-if="components.length > 0">
                  <div v-for="c in components" :key="c._id">
                    <button class="dropdown-item py-3" @click="onEmailComponentClicked(c)">{{ c.name }}</button>
                  </div>
                </SimpleBar>
                <div v-else class="p-3">
                  <label class="small text-center">{{ $t('journeys.journey_workflow_components_channel_variants_email_config_no_components') }}</label>
                </div>
                <div class="dropdown-divider my-0"></div>
                  <a
                  class="dropdown-item text-primary text-center my-2"
                  href="javascript: void(0);">{{ $t('journeys.journey_workflow_components_channel_variants_email_config_new_component') }}</a>
              </b-dropdown>
        </div>
          </template>
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <button class="btn btn-outline-primary" @click="onSaveAsTemplateClicked">{{$t('personalizations.save_as_template')}}</button>
              <div class="ml-auto">
                <b-button variant="secondary" class="me-2" @click="showVariableModal = false">{{$t('common.cancel')}}</b-button>
                <button class="btn btn-outline-primary me-2" @click="onConfirmBodyClicked(true)">{{$t('common.save')}}</button>
                <b-button variant="primary" class="btn btn-primary" @click.prevent="onConfirmBodyClicked(false)">{{ $t('common.confirm') }}</b-button>
              </div>
            </div>
          </template>
          <CustomWebBuilder v-if="emailType == 'html'" ref="customWebBuilder" :items="items" :affinity_Types="affinity_Types" :categories="categories" :variant="variant" :email="true"/>
          <EmailBuilder v-if="emailType == 'drag'" ref="dragEmailBuilder" />
      </b-modal>
      <b-modal
        :size="emailType=='drag' ? 'lg' : 'md'"
        :title="$t('journeys.journey_workflow_components_channel_variants_email_config_add_component')"
        v-model="showComponentAddModal">
        <div v-if="emailType=='drag'">
          <label class="small">{{$t('journeys.journey_workflow_components_channel_variants_email_config_added_component')}}</label>
          <div style="display: flex;justify-content: center;align-items: center;">
            <video width="80%" autoplay controls style="    border-width: thin;border-color: lightgray;border-style: solid;">
              <source src="@/assets/images/brands/reco_email_block-1.mp4" type="video/mp4">
            </video>
          </div>
        </div>
        <div v-else>
          <label class="small">{{ $t('journeys.journey_workflow_components_channel_variants_email_config_added_component_html') }}</label>
        </div>
        <template #modal-footer="{ ok }">
          <b-button variant="primary" @click="ok()">{{$t('common.accept')}}</b-button>
      </template>
      </b-modal>
      <b-modal
        :title="$t('templates.new_template')"
        v-model="showNewTemplate"
        title-class="font-18"
        @ok.prevent="onConfirmNewTemplateClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
        >
        <div class="row">
          <div class="mb-3">
            <label for="promotionName">{{$t('personalizations.name')}}*</label>
            <input
                id="promotionName"
                v-model="template.name"
                name="name"
                type="text"
                class="form-control"
                :placeholder="$t('personalizations.name_placeholder')"
                :class="{ 'is-invalid': submitted && $v.template.name.$error}"
            />
            <div v-if="submitted && !$v.template.name.required" class="invalid-feedback">
              {{ $t('personalizations.name_required') }}
            </div>
          </div>
        </div>
      </b-modal>
      
      <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="mdi mdi-wizard-hat font-size-16 align-middle me-1"></i>
                  {{$t('broadcasts.generate_text')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <AITextGenerator :channel="'Email'" @onTextSelected="onSuggestedTextSelected" :field="field" :limit="limit" ></AITextGenerator>
              </div>
          </div>
      </Drawer>
      <Drawer
        @close="rightTestDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightTestDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  {{$t('broadcasts.test_message_email')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <TestChannel :channel="'email'" :message="testMessage" @onTestSent="openRightTestDrawer = false"></TestChannel>
              </div>
          </div>
      </Drawer>
      <div class="emoji-menu" v-if="showEmojiPicker" :style="emojiPickerStyle">
        <picker @select="handleEmojiClick" :showPreview="false" :sheetSize="32" :id="'emoji-picker'"/>
      </div>
    </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}

.emoji-menu{
  position: absolute;
  right: calc(var(--bs-gutter-x) * 0.5);
  z-index: 999;
  overflow: hidden;
}

.email-container{
  width: 200px;
  height: 70px;
  border-style: solid;
  border-radius: 8px;
  border-color: lightgray;
  border-width: 0.5px;
}

.text-length{
  position: absolute;
  right: 12px;
  font-size: 10px;
  margin-top: 2px;
}

</style>

<style>
#integration-modal .modal-body {
  background-color: var(--bs-body-bg);
}

.left-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Optional: Adjust spacing between input and buttons */
}

.center-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}
</style>