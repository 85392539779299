
import BroadcastService from '../../services/broadcasts'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createBroadcast ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      BroadcastService.createBroadcast(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating broadcast'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getBroadcasts ({ commit },params) {

    return new Promise((resolve, reject) => { 
      BroadcastService.getBroadcasts(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  getBroadcast ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      BroadcastService.getBroadcast(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteBroadcast ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      BroadcastService.deleteBroadcast(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateBroadcast ({ commit }, broadcast) {

    return new Promise((resolve, reject) => { 
      BroadcastService.updateBroadcast(broadcast).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  generateMessage ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      BroadcastService.generateMessage(payload).then(response => { 
        if (response.data) { 
          resolve(response.data)
        }else{
          reject({message: 'Error generating message'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  testMessage ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      BroadcastService.testMessage(payload).then(response => { 
        if (response.data) { 
          resolve(response.data)
        }else{
          reject({message: 'Error testing message'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  countCustomerBroadcast ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      BroadcastService.countCustomerBroadcast(payload).then(response => { 
        if (response.data) { 
          resolve(response.data)
        }else{
          reject({message: 'Error count customers'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getBroadcastExecutions ({ commit },params) {

    return new Promise((resolve, reject) => { 
      BroadcastService.getBroadcastExecutions(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  
}